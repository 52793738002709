
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Button, Dropdown, Form, Card, Pagination, Tooltip, OverlayTrigger, Badge, Modal } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import CheckAll from 'components/check-all/CheckAll';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { convertirFormatoFechaCorto, deleteDocument, formatNumberToPesos, obtenerColorBadge } from 'helper';

const OrderList = ({ db, clientes, pedidos, visitas, cotizaciones }) => {
  console.log("ol clientes: ", clientes);
  console.log("ol pedidos: ", pedidos);
  console.log("ol visitas: ", visitas);
  const title = 'Lista de Pedidos';
  const description = 'Lista de Pedidos';
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalTittle, setModalTittle] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const history = useHistory();


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Resetear a la primera página
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtrar y paginar pedidos
  const filteredPedidos = pedidos
    .filter((pedido) => {
      const clienteEncontrado = clientes.find(
        (clienteSearch) => clienteSearch.clienteId.toString() === pedido.clienteId.toString()
      );
      return pedido.pedidoId.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        pedido.estado.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        clienteEncontrado?.nombre.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        clienteEncontrado?.apellido.toString().toLowerCase().includes(searchTerm.toLowerCase())
    }).sort((a, b) => b.pedidoId - a.pedidoId);

  const allItems = Array.from({ length: filteredPedidos.length }, (_, i) => i + 1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPedidos = filteredPedidos.slice(indexOfFirstItem, indexOfLastItem);

  const checkItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((x) => x !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const toggleCheckAll = (allSelect) => {
    if (allSelect) {
      setSelectedItems(allItems);
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Dashboard</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}

          {/* Top Buttons Start */}

          {/* Top Buttons End */}
        </Row>
      </div>

      <Row className="mb-3">
        <Col md="5" lg="3" xxl="2" className="mb-1">
          {/* Search Start */}
          <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
            <Form.Control type="text" placeholder="Buscar" onChange={handleSearchChange} />
            <span className="search-magnifier-icon">
              <CsLineIcons icon="search" />
            </span>
            <span className="search-delete-icon d-none">
              <CsLineIcons icon="close" />
            </span>
          </div>
          {/* Search End */}
        </Col>

      </Row>

      {/* List Header Start */}
      <Row className="g-0 h-100 align-content-center d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort">
        <Col lg="1" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
          <div className="text-muted text-small cursor-pointer">ID</div>
        </Col>
        <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer">NOMBRE</div>
        </Col>
        <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer">DETALLE</div>
        </Col>
        {/* <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer sort">F. INICIO</div>
        </Col> */}
        <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer">F. ENTREGA</div>
        </Col>
        <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer" style={{alignSelf:'center'}}>ABONADO</div>
        </Col>
        <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer " style={{alignSelf:'center'}}>SALDO</div>
        </Col>
        <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer" style={{alignSelf:'center'}}>VALOR TOTAL</div>
        </Col>
        <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer ">ESTADO</div>
        </Col>
        <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer"></div>
        </Col>
      </Row>
      {/* List Header End */}

      {/* List Items Start */}
      {currentPedidos.map((pedido, index) => {

        const clienteEncontrado = clientes.find(
          (clienteSearch) => clienteSearch.clienteId.toString() === pedido.clienteId.toString()
        );
        const sumaMontos = (pedido?.pagos?.reduce((acumulador, pago) => acumulador + parseFloat(pago.monto), 0))


        return (
          <Card key={index} className={`mb-2 ${selectedItems.includes(index + 1) && 'selected'}`}>
            <Card.Body className="pt-0 pb-0 sh-30 sh-lg-8">
              <Row className="g-0 h-100 align-content-center">
                <Col xs="11" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-1 order-lg-1 h-lg-100 position-relative">
                  <div className="text-muted text-small d-lg-none">Id</div>
                  <NavLink to={`/orders/detail?id=${pedido.pedidoId}`} className="text-truncate h-100 d-flex align-items-center">
                    {pedido.pedidoId}
                  </NavLink>
                </Col>
                <Col xs="6" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-3 order-lg-2">
                  <div className="text-muted text-small d-lg-none">Client Name</div>
                  <div className="text-alternate">{`${clienteEncontrado?.nombre != '' ? clienteEncontrado?.nombre : '- -'} ${clienteEncontrado?.apellido != '' ? clienteEncontrado?.apellido : '- -'}`}</div>
                </Col>
                <Col xs="6" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-3">
                  <div className="text-muted text-small d-lg-none">Items</div>
                  <NavLink to="/customers/detail" className="text-truncate h-100 d-flex align-items-center body-link">
                    {pedido.items.length > 0 ? pedido.items.length : '- -'}
                  </NavLink>
                </Col>
                {/* <Col xs="6" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-3">
                  <div className="text-muted text-small d-lg-none">Fecha Inicio</div>
                  <div className="text-alternate">{`${pedido.fecha.inicio != '' ? convertirFormatoFechaCorto((pedido.fecha.inicio).toDate()) : '- -'}`}</div>
                </Col> */}
                <Col xs="6" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-3">
                  <div className="text-muted text-small d-lg-none">Fecha Entrega</div>
                  <div className="text-alternate" >{`${pedido.fecha.entrega != '' ? convertirFormatoFechaCorto((pedido.fecha.entrega).toDate()) : '- -'}`}</div>
                </Col>
                <Col xs="6" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-3">
                  <div className="text-muted text-small d-lg-none">Abonado</div>
                  <div className="text-alternate" style={{alignSelf:'center'}}>{`$${formatNumberToPesos(Math.round(sumaMontos))}`}</div>
                </Col>
                <Col xs="6" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-3">
                  <div className="text-muted text-small d-lg-none">SALDO</div>
                  <div className="text-alternate" style={{alignSelf:'center'}}>{`$${formatNumberToPesos(Math.round(pedido.valor.total - sumaMontos))}`}</div>
                </Col>
                <Col xs="6" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-3">
                  <div className="text-muted text-small d-lg-none">Valor Total</div>
                  <div className="text-alternate" style={{alignSelf:'center'}}>{pedido.valor.total != '' ? `$${formatNumberToPesos(Math.round(pedido.valor.total))}` : '- -'}</div>
                </Col>
                <Col xs="6" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-md-0 order-last order-md-5">
                  <div className="text-muted text-small d-md-none">Status</div>
                  <div>
                    <Badge bg={`${obtenerColorBadge({estado:pedido.estado})}`}>{`${pedido.estado != '' ? pedido.estado : '- -'}`}</Badge>
                  </div>
                </Col>
                <Col xs="1" lg="1" className="d-flex flex-column justify-content-center align-items-md-end mb-2 mb-md-0 order-2 text-end order-md-last">
                  <div
                    className="sw-3 me-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {

                      var clickDelete = deleteDocument({
                        db: db,
                        objeto: pedido,
                        documento: 'pedidos'
                      });
                      if (clickDelete) {
                        console.log("EL PEDIDO SE ELIMINO CON EXITO");
                        history.push(`/orders/list`)
                      } else {
                        setModalTittle("ERROR AL ELIMINAR EL PEDIDO");
                        setIsModalShow(true);
                        console.log("ERROR AL ELIMINAR EL PEDIDO");
                      }

                    }}
                  >
                    <CsLineIcons
                      icon="bin"
                      size="17"
                      className="text-primary"
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )
      })}
      {/* List Items End */}

      {/* Pagination Start */}
      <div className="d-flex justify-content-center mt-5">
        <Pagination>
          <Pagination.Prev
            className="shadow"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <CsLineIcons icon="chevron-left" />
          </Pagination.Prev>
          {Array.from({ length: Math.ceil(filteredPedidos.length / itemsPerPage) }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              className="shadow"
              active={i + 1 === currentPage}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            className="shadow"
            disabled={currentPage === Math.ceil(filteredPedidos.length / itemsPerPage)}
            onClick={() => handlePageChange(currentPage +

              1)}
          >
            <CsLineIcons icon="chevron-right" />
          </Pagination.Next>
        </Pagination>
      </div>
      {/* Pagination End */}

      {/* Categories Modal Start */}
      <Modal
        className="modal-center"
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
      >
        <Modal.Body closeButton>
          <Modal.Title style={{ textAlignLast: "center" }} as="h5">
            {modalTittle}
          </Modal.Title>
          <Row>
            <Col lg="6">
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                variant="primary"
                onClick={() => setIsModalShow(false)}
              >
                <span>Cerrar</span> <CsLineIcons icon="chevron-right" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Categories Modal End */}
    </>
  );
};

export default OrderList;