import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Row, Col, Button, Dropdown, Card, Modal } from "react-bootstrap";
import HtmlHead from "components/html-head/HtmlHead";
import generatePDF from "react-to-pdf";
import CsLineIcons from "cs-line-icons/CsLineIcons";
import {
  addPedido,
  convertirFormatoFechaAlternativo,
  convertirFormatoFechaCorto,
  deleteDocument,
  formatNumberToPesos,
  sumarDiasHabiles,
} from "helper";

const QuotesDetail = ({ db, codigos, clientes, pedidos, visitas, cotizaciones }) => {
  const title = "Detalles de Cotización";
  const description = "Detalles de Cotización";
  const history = useHistory();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalTittle, setModalTittle] = useState("");
  const [cotizacion, setCotizacion] = useState(null);
  const [clienteEncontrado, setClienteEncontrado] = useState(null);

  const targetRef = useRef();

  useEffect(async () => {
    console.log('clientes : ', clientes)
    // Encuentra el cotizacion que coincida con el id
    const cotizacionEncontrado = await cotizaciones.find(
      (cotizacionSearch) => {
        console.log('cotizacion search: ', cotizacionSearch.cotizacionId)
        return (cotizacionSearch.cotizacionId.toString() === id)
      }
    );

    console.log('cotizacion ', cotizacionEncontrado)
    const p = cotizacionEncontrado || cotizaciones[cotizaciones.length - 1];
    console.log("p ", p);
    // Actualiza el estado con el cotizacion encontrado
    setCotizacion(p);

    if (p !== undefined) {
      const c = clientes.find(
        (clienteSearch) =>
          clienteSearch.clienteId.toString() === p.clienteId.toString()
      );

      setClienteEncontrado(c);
    }

    // Coloca el código adicional que necesitas con el cotizacion encontrado aquí
  }, [cotizaciones, id]);

  if (
    cotizacion === null ||
    cotizacion === undefined ||
    clienteEncontrado === null ||
    clienteEncontrado === undefined
  ) {
    return <div className="loading" />;
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink
              className="muted-link pb-1 d-inline-block hidden breadcrumb-back"
              to="/orders"
            >
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Cotizaciones</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}

          {/* Top Buttons Start */}
          {cotizacion !== undefined ? (
            <Col
              xs="12"
              sm="auto"
              className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3"
            >
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                style={{ marginRight: "1rem" }}
                variant="primary"
                onClick={() =>
                  generatePDF(targetRef, {
                    filename: `cotizacion${cotizacion.cotizacionId}`,
                  })
                }
              >
                <span>Descargar</span>
              </Button>
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                style={{ marginRight: "1rem" }}
                variant="primary"
                onClick={() => {
                  var clickAddPedido = addPedido({
                    db: db,
                    codigos: codigos,
                    clientePedido: clienteEncontrado,
                    pedido: cotizacion,
                  });
                  if (clickAddPedido) {
                    console.log("El PEDIDO SE AGREGO CON EXITO");
                    history.push(`/orders/list`)
                  } else {
                    setModalTittle("ERROR AL AGREGAR EL PEDIDO");
                    setIsModalShow(true);
                    console.log("ERROR AL AGREGAR EL PEDIDO");
                  }
                }}
              >
                <span>Crear pedido</span>
              </Button>
              <Dropdown className="w-100 w-md-auto">
                <Dropdown.Toggle className="w-100 w-md-auto" variant="primary">
                  Status: {cotizacion.estado}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Status: Pendiente</Dropdown.Item>
                  <Dropdown.Item>Status: Enviada</Dropdown.Item>
                  <Dropdown.Item>Status: Aceptada</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="ms-1">
                <Dropdown.Toggle
                  className="btn-icon btn-icon-only dropdown-toggle-no-arrow"
                  variant="outline-primary"
                >
                  <CsLineIcons icon="more-horizontal" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Edit</Dropdown.Item>
                  <Dropdown.Item onClick={() => {

                    var clickDelete = deleteDocument({
                      db: db,
                      objeto: cotizacion,
                      documento: 'cotizaciones'
                    });
                    if (clickDelete) {
                      console.log("LA COTIZACIÓN SE ELIMINO CON EXITO");
                      history.push(`/orders/list`)
                    } else {
                      setModalTittle("ERROR AL ELIMINAR LA COTIZACIÓN");
                      setIsModalShow(true);
                      console.log("ERROR AL ELIMINAR LA COTIZACIÓN");
                    }

                  }}>Eliminar</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          ) : (
            <></>
          )}
          {/* Top Buttons End */}
        </Row>
      </div>

      <Row>
        <Col xl="9" xxl="9">
          {cotizacion !== undefined ? (
            <>
              <h2 className="small-title">Cotización</h2>
              <Card className="mb-5" ref={targetRef}>
                <Card.Body>
                  <div className="mb-5">
                    <Row>
                      <Col xs="3">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src="/img/logo/logoMuebleria.jpeg"
                            alt="Logo Mueblería"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </Col>
                      <Col
                        xs="9"
                        className="text-center"
                        style={{ alignSelf: "center" }}
                      >
                        <Row>
                          <h1 className="font-weight-bold">COTIZACIÓN</h1>
                        </Row>
                        <Row>
                          <h4 className="font-weight-bold">
                            Muebles a Tu Estilo SpA
                          </h4>
                        </Row>
                        <Row>
                          <p className="font-weight-bold">77.236.079-7</p>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col xs="6">
                        <Row>
                          <p className="mb-0">
                            Dirección: Camino Lonquen Norte paradero 15 1/2
                            Sitio 6, Calera de Tango, Santiago, Chile.
                          </p>
                        </Row>
                        <Row>
                          <p className="mb-0">Celular: +56 9 2187 1843</p>
                        </Row>
                        <Row>
                          <p className="mb-0">
                            Instagram: @mueblesatuestilo.oficial
                          </p>
                        </Row>
                        <Row>
                          <p className="mb-0">
                            Asesor de Venta: Amador Andrés Soto Alcantar
                          </p>
                        </Row>
                      </Col>
                      <Col xs="4" style={{ marginLeft: "auto" }}>
                        <Row className="mb-1">
                          <Col xs="7" style={{ alignSelf: "center" }}>
                            <h5 style={{ textAlign: "right" }}>FECHA</h5>
                          </Col>
                          <Col
                            xs="5"
                            style={{
                              borderStyle: "solid",
                              alignSelf: "center",
                            }}
                          >
                            <h5
                              className="mb-0"
                              style={{ textAlign: "center" }}
                            >
                              {`${cotizacion.fechaCotizacion != ""
                                ? convertirFormatoFechaCorto(
                                  cotizacion.fechaCotizacion.toDate()
                                )
                                : "- -"
                                }`}
                            </h5>
                          </Col>
                        </Row>
                        <Row className="mb-1">
                          <Col xs="7" style={{ alignSelf: "center" }}>
                            <h5 style={{ textAlign: "right" }}>COTIZACIÓN #</h5>
                          </Col>
                          <Col
                            xs="5"
                            style={{
                              borderStyle: "solid",
                              alignSelf: "center",
                            }}
                          >
                            <h5
                              className="mb-0"
                              style={{ textAlign: "center" }}
                            >
                              {cotizacion.cotizacionId}
                            </h5>
                          </Col>
                        </Row>
                        <Row className="mb-1">
                          <Col xs="7" style={{ alignSelf: "center" }}>
                            <h5 style={{ textAlign: "right" }}>VÁLIDO HASTA</h5>
                          </Col>
                          <Col
                            xs="5"
                            style={{
                              borderStyle: "solid",
                              alignSelf: "center",
                            }}
                          >
                            <h5
                              className="mb-0"
                              style={{ textAlign: "center" }}
                            >
                              {`${cotizacion.fechaCotizacion != ""
                                ? sumarDiasHabiles(
                                  cotizacion.fechaCotizacion.toDate(),
                                  7
                                )
                                : "- -"
                                }`}
                            </h5>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <h5 className="font-weight-bold">CLIENTE</h5>
                      <hr className="w-90" style={{ opacity: "0.75" }} />
                      <Col xs="6">
                        <h6>
                          Nombre:{" "}
                          {`${clienteEncontrado.nombre != ""
                            ? clienteEncontrado.nombre
                            : "- -"
                            } ${clienteEncontrado.apellido != ""
                              ? clienteEncontrado.apellido
                              : "- -"
                            }`}
                        </h6>
                      </Col>
                      <Col xs="6">
                        <h6>
                          Telefóno:{" "}
                          {`${clienteEncontrado.telefono != ""
                            ? clienteEncontrado.telefono
                            : "- -"
                            }`}
                        </h6>
                      </Col>
                      <Col xs="6">
                        <h6>
                          Dirección:{" "}
                          {`${clienteEncontrado.direccion != ""
                            ? clienteEncontrado.direccion
                            : "- -"
                            }`}
                        </h6>
                      </Col>
                      <Col xs="6">
                        <h6>
                          Rut:{" "}
                          {`${clienteEncontrado.rut != ""
                            ? clienteEncontrado.rut
                            : "- -"
                            }`}
                        </h6>
                      </Col>
                    </Row>

                    <Row>
                      <h5 className="font-weight-bold">PEDIDO</h5>
                      <hr className="w-90" style={{ opacity: "0.75" }} />
                    </Row>
                    <div className="mb-4">
                      <Row className="mb-4">
                        <Col>
                          <div className="ps-4 pt-0 pb-2 pe-0 h-100">
                            {cotizacion.items !== "" ? (
                              cotizacion.items.map((item) => (
                                <Row className="g-0 pb-2 mb-4 align-items-start align-content-center">
                                  <Col
                                    xs="12"
                                    className="d-flex flex-column mb-2"
                                  >
                                    <div>{item.tipoMueble || "N/A"}</div>
                                    {item.selectedColorCubierta !== null ? (
                                      <div className="text-muted text-small">
                                        Color Cubierta{" "}
                                        {item.selectedColorCubierta.label ||
                                          " "}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {item.selectedColorExterior !== null &&
                                      item.selectedColorInterior !== null ? (
                                      item.selectedColorExterior.label ===
                                        item.selectedColorInterior.label ? (
                                        <div className="text-muted text-small">
                                          Color{" "}
                                          {item.selectedColorExterior.label}
                                        </div>
                                      ) : (
                                        <>
                                          <div className="text-muted text-small">
                                            Color Interior{" "}
                                            {item.selectedColorInterior.label}
                                          </div>
                                          <div className="text-muted text-small">
                                            Color Exterior{" "}
                                            {item.selectedColorExterior.label}
                                          </div>
                                        </>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    {
                                      item.selectedManillas?.label !== 'No Aplica' ?
                                        <div className="text-muted text-small">
                                          Manillas{" "}
                                          {item.selectedManillas?.label}
                                        </div> : <></>
                                    }

                                    <div className="text-muted text-small">
                                      {item.detalle || " "}
                                    </div>
                                    {item.extras.map((extra) => (
                                      <div key={uuidv4()}>
                                        <div className="text-small">
                                          {extra.tipo.label || " "}
                                        </div>
                                        <div style={{ marginLeft: "0.75rem" }}>
                                          <div className=" text-muted text-small">
                                            {extra.detalle || " "}
                                          </div>
                                          <Row className="g-0">
                                            <Col
                                              xs="6"
                                              className="d-flex flex-row pe-2 align-items-end text-alternate"
                                            >
                                              <span>
                                                <span className="text-small">
                                                  {extra.cantidad}
                                                </span>
                                              </span>
                                              <span className="text-muted ms-1 me-1">
                                                x
                                              </span>
                                              <span>
                                                <span className="text-small">
                                                  ${formatNumberToPesos(extra.tipo.precio)}
                                                </span>
                                              </span>
                                            </Col>
                                            <Col
                                              xs="6"
                                              className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                            >
                                              <span>
                                                <span className="text-small">
                                                  $
                                                  {formatNumberToPesos(Math.round(extra.cantidad *
                                                    extra.tipo.precio))}
                                                </span>
                                              </span>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    ))}
                                  </Col>
                                  <Col
                                    xs="12"
                                    className="d-flex flex-column mb-md-0 pt-1"
                                  >
                                    {
                                      item.precioMueble !== "" ?

                                        <Row className="g-0">
                                          <Col
                                            xs="6"
                                            className="d-flex flex-row pe-2 align-items-end text-alternate"
                                          >
                                            <span>

                                            </span>
                                          </Col>
                                          <Col
                                            xs="6"
                                            className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                          >
                                            <span>
                                              <span className="text-small">
                                                {item.cantidadMueble} x ${formatNumberToPesos(Math.round(item.medidas * item.precio))}
                                              </span>
                                            </span>
                                          </Col>
                                        </Row>
                                        :
                                        <Row className="g-0">
                                          <Col
                                            xs="6"
                                            className="d-flex flex-row pe-2 align-items-end text-alternate"
                                          >
                                            <span>{item.medidas} metros</span>
                                            <span className="text-muted ms-1 me-1">x</span>
                                            <span>
                                              <span className="text-small">
                                                ${formatNumberToPesos(item.precio)} metro lineal
                                              </span>
                                            </span>
                                          </Col>
                                          <Col
                                            xs="6"
                                            className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                          >
                                            <span>
                                              <span className="text-small">
                                                {item.cantidadMueble} x ${formatNumberToPesos(Math.round(item.medidas * item.precio))}
                                              </span>
                                            </span>
                                          </Col>
                                        </Row>
                                    }
                                    <Row className="g-0">
                                      <hr
                                        className="w-100 mb-0 mt-2"
                                        style={{ opacity: "0.25" }}
                                      />
                                      <Col
                                        xs="6"
                                        className="d-flex flex-row pe-2 align-items-end text-alternate"
                                      >
                                        <span>
                                          Precio total{" "}
                                          {item.tipoMueble || " "}
                                        </span>
                                      </Col>
                                      <Col
                                        xs="6"
                                        className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                      >
                                        <span>
                                          <span>${formatNumberToPesos(item.precioTotal)}</span>
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>

                                </Row>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row className="g-0 mb-2">
                        <Col xs="auto" className="ms-auto ps-3 ">
                          Neto
                        </Col>
                        <Col xs="auto" className="sw-13 text-end">
                          <span>
                            <span className="text-small ">
                              ${formatNumberToPesos(cotizacion.valor.neto)}
                            </span>
                            {/* {pedido.detalles.reduce((acum, item) => acum + item.total, 0)} */}
                          </span>
                        </Col>
                      </Row>
                      <Row className="g-0 mb-2">
                        <Col xs="auto" className="ms-auto ps-3">
                          Descuento
                        </Col>
                        <Col xs="auto" className="sw-13 text-end">
                          <span>
                            <span className="text-small ">
                              ${formatNumberToPesos(cotizacion.valor.descuento)}
                            </span>
                            {/* {pedido.detalles.reduce((acum, item) => acum + item.total, 0)} */}
                          </span>
                        </Col>
                      </Row>
                      <Row className="g-0 mb-2">
                        <Col xs="auto" className="ms-auto ps-3 ">
                          IVA
                        </Col>
                        <Col xs="auto" className="sw-13 text-end">
                          <span>
                            <span className="text-small ">
                              ${formatNumberToPesos(cotizacion.valor.iva)}
                            </span>
                            {/* {(pedido.detalles.reduce((acum, item) => acum + item.total, 0))*0.19} */}
                          </span>
                        </Col>
                      </Row>
                      <Row className="g-0 mb-2">
                        <Col
                          xs="auto"
                          className="ms-auto ps-3 font-weight-bold"
                        >
                          Total
                        </Col>
                        <Col
                          xs="auto"
                          className="sw-13 text-end font-weight-bold"
                        >
                          <span>
                            <span>${formatNumberToPesos(cotizacion.valor.total)}</span>
                            {/* {((pedido.detalles.reduce((acum, item) => acum + item.total, 0))+ ((pedido.detalles.reduce((acum, item) => acum + item.total, 0))*0.19))} */}
                          </span>
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <h5 className="font-weight-bold">
                        TÉRMINOS Y CONDICIONES
                      </h5>
                      <hr className="w-90" style={{ opacity: "0.75" }} />
                      <Col xs="12">
                        <Row>
                          <p className="mb-0">
                            1. Incluye Materiales, Mano de Obra e Instalación.
                          </p>
                        </Row>
                        <Row>
                          <p className="mb-0">
                            2. Si acepta la cotización, se debe abonar el 50%
                            para rectificar y comenzar.
                          </p>
                        </Row>
                        <Row>
                          <p className="mb-0">
                            3. Fecha estimada de entrega, es de 15 a 20 días
                            hábiles después del primer abono.
                          </p>
                        </Row>
                        <Row>
                          <p className="mb-0">
                            4. Forma de pago solo Efectivo o Transferencia.
                          </p>
                        </Row>
                        <Row>
                          <p className="mb-0">
                            5. Se considera 1 año de garantía.
                          </p>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="1"></Col>
                      <Col xs="5" style={{ height: '100%' }}>

                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                          }}
                        >
                          {/* Imagen de la firma */}
                          <img
                            src="/img/logo/fas.png"
                            alt="Firma"
                            style={{
                              width: "60%",
                              height: "100%",
                              marginLeft: '20%',
                              objectFit: "cover",
                              position: "absolute",
                              top: '2rem',
                            }}
                          />

                          {/* Línea */}
                          <Row
                            style={{
                              justifyContent: "center",
                              position: "relative",
                              marginTop: '10rem'
                            }}
                          >
                            <hr
                              className="w-80 mt-2 mb-0"
                              style={{ opacity: "0.75", zIndex: 1 }}
                            />
                          </Row>

                          {/* Párrafo */}
                          <Row
                            className="text-center"
                            style={{ position: "relative", zIndex: 2 }}
                          >
                            <p className="mb-0">
                              FIRMA Y RUT REPRESENTANTE LEGAL
                            </p>
                          </Row>
                        </div>
                      </Col>
                      <Col xs="auto"></Col>
                      <Col xs="5" style={{ height: '100%' }}>

                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                          }}
                        >
                          {/* Línea */}
                          <Row
                            style={{
                              justifyContent: "center",
                              position: "relative",
                              marginTop: '10rem'
                            }}
                          >
                            <hr
                              className="w-80 mt-2 mb-0"
                              style={{ opacity: "0.75", zIndex: 1 }}
                            />
                          </Row>

                          {/* Párrafo */}
                          <Row
                            className="text-center"
                            style={{ position: "relative", zIndex: 2 }}
                          >
                            <p className="mb-0">
                              FIRMA Y RUT CLIENTE
                            </p>
                          </Row>
                        </div>
                      </Col>
                      <Col xs="1"></Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </>
          ) : (
            <></>
          )}
        </Col>

        <Col xl="3" xxl="3">
          {/* Address Start */}
          <h2 className="small-title">Cliente</h2>
          <Card className="mb-5">
            <Card.Body className="mb-n5">
              <div className="mb-5">
                <p className="text-small text-muted mb-2">Datos</p>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="user"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.nombre != ""
                    ? clienteEncontrado.nombre
                    : "- -"
                    } ${clienteEncontrado.apellido != ""
                      ? clienteEncontrado.apellido
                      : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="credit-card"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.rut != "" ? clienteEncontrado.rut : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="pin"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.direccion != ""
                    ? clienteEncontrado.direccion
                    : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="at-sign"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.email != ""
                    ? clienteEncontrado.email
                    : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="phone"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.telefono != ""
                    ? clienteEncontrado.telefono
                    : "- -"
                    }`}</Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
          {/* Address End */}
          {/* Status Start */}
          <h2 className="small-title">Detalles</h2>
          <Row className="g-2 mb-5">
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="tag" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Id
                      </div>
                      <div className="text-primary">
                        {cotizacion.cotizacionId}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons
                          icon="clipboard"
                          className="text-primary"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Estado
                      </div>
                      <div className="text-primary">{`${cotizacion.estado != "" ? cotizacion.estado : "- -"
                        }`}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="calendar" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Fecha Creación
                      </div>
                      <div className="text-primary">{`${cotizacion.fechaCotizacion != ""
                        ? cotizacion.fechaCotizacion !== '' ? convertirFormatoFechaAlternativo(
                          cotizacion.fechaCotizacion.toDate()
                        ) : "- -"
                        : "- -"
                        }`}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="calendar" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Fecha Envío
                      </div>
                      <div className="text-primary">{`${cotizacion.fechaEnvio !== undefined
                        ? cotizacion.fechaEnvio !== '' ? convertirFormatoFechaAlternativo(
                          cotizacion.fechaEnvio.toDate()
                        ) : "- -"
                        : "- -"
                        }`}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* Status End */}
        </Col>
      </Row>
      {/* Categories Modal Start */}
      <Modal
        className="modal-center"
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
      >
        <Modal.Body closeButton>
          <Modal.Title style={{ textAlignLast: "center" }} as="h5">
            {modalTittle}
          </Modal.Title>
          <Row>
            <Col lg="6">
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                variant="primary"
                onClick={() => setIsModalShow(false)}
              >
                <span>Cerrar</span> <CsLineIcons icon="chevron-right" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Categories Modal End */}
    </>
  );
};

export default QuotesDetail;
