const enMessages = {
  'menu.dashboards': 'Tableros',
  'menu.default': 'Predeterminado',
  'menu.visual': 'Visual',
  'menu.analytic': 'Analítico',
  'menu.apps': 'Aplicaciones',
  'menu.calendar': 'Calendario',
  'menu.chat': 'Chat',
  'menu.contacts': 'Contactos',
  'menu.mailbox': 'Buzón',
  'menu.tasks': 'Tareas',
  'menu.pages': 'Páginas',
  'menu.authentication': 'Autenticación',
  'menu.login': 'Iniciar sesión',
  'menu.register': 'Registrarse',
  'menu.forgot-password': 'Olvidé mi contraseña',
  'menu.reset-password': 'Restablecer contraseña',
  'menu.blog': 'Blog',
  'menu.home': 'Inicio',
  'menu.grid': 'Cuadrícula',
  'menu.list': 'Lista',
  'menu.detail': 'Detalle',
  'menu.add': 'Agregar',
  'menu.visits': 'Visitas',
  'menu.quotes': 'Cotizaciones',
  'menu.miscellaneous': 'Misceláneo',
  'menu.faq': 'Preguntas frecuentes',
  'menu.knowledge-base': 'Base de conocimientos',
  'menu.error': 'Error',
  'menu.coming-soon': 'Próximamente',
  'menu.pricing': 'Precios',
  'menu.search': 'Buscar',
  'menu.mailing': 'Correo',
  'menu.empty': 'Vacío',
  'menu.portfolio': 'Portafolio',
  'menu.profile': 'Perfil',
  'menu.standard': 'Estándar',
  'menu.settings': 'Configuración',
  'menu.blocks': 'Bloques',
  'menu.images': 'Imágenes',
  'menu.tabular-data': 'Datos tabulares',
  'menu.thumbnails': 'Miniaturas',
  'menu.cta': 'Llamada a la acción',
  'menu.gallery': 'Galería',
  'menu.stats': 'Estadísticas',
  'menu.steps': 'Pasos',
  'menu.details': 'Detalles',
  'menu.interface': 'Interfaz',
  'menu.components': 'Componentes',
  'menu.accordion': 'Acordeón',
  'menu.alerts': 'Alertas',
  'menu.badge': 'Insignia',
  'menu.breadcrumb': 'Migas de pan',
  'menu.buttons': 'Botones',
  'menu.button-group': 'Grupo de botones',
  'menu.card': 'Tarjeta',
  'menu.close-button': 'Botón de cierre',
  'menu.collapse': 'Colapsar',
  'menu.dropdowns': 'Desplegables',
  'menu.list-group': 'Lista de grupos',
  'menu.modal': 'Modal',
  'menu.navs': 'Navegaciones',
  'menu.offcanvas': 'Offcanvas',
  'menu.pagination': 'Paginación',
  'menu.popovers': 'Popovers',
  'menu.progress': 'Progreso',
  'menu.spinners': 'Spinners',
  'menu.toasts': 'Toasts',
  'menu.tooltips': 'Tooltips',
  'menu.forms': 'Formularios',
  'menu.layouts': 'Diseños',
  'menu.validation': 'Validación',
  'menu.wizard': 'Asistente',
  'menu.input-group': 'Grupo de entrada',
  'menu.input-mask': 'Máscara de entrada',
  'menu.generic-forms': 'Formularios genéricos',
  'menu.controls': 'Controles',
  'menu.autocomplete': 'Autocompletar',
  'menu.checkbox-radio': 'Checkbox-Radio',
  'menu.date-picker': 'Selector de fecha',
  'menu.dropzone': 'Zona de soltar',
  'menu.editor': 'Editor',
  'menu.input-spinner': 'Spinner de entrada',
  'menu.rating': 'Calificación',
  'menu.select': 'Seleccionar',
  'menu.slider': 'Deslizador',
  'menu.tags': 'Etiquetas',
  'menu.time-picker': 'Selector de tiempo',
  'menu.plugins': 'Plugins',
  'menu.carousel': 'Carrusel',
  'menu.charts': 'Gráficos',
  'menu.clamp': 'Clamp',
  'menu.context-menu': 'Menú contextual',
  'menu.datatables': 'Tablas de datos',
  'menu.editable-rows': 'Filas editables',
  'menu.editable-boxed': 'Cajas editables',
  'menu.server-side': 'Lado del servidor',
  'menu.boxed-variations': 'Variaciones en caja',
  'menu.lightbox': 'Caja de luz',
  'menu.maps': 'Mapas',
  'menu.notification': 'Notificación',
  'menu.players': 'Jugadores',
  'menu.scrollbar': 'Barra de desplazamiento',
  'menu.shortcuts': 'Atajos',
  'menu.sortable': 'Ordenable',
  'menu.content': 'Contenido',
  'menu.icons': 'Iconos',
  'menu.cs-line-icons': 'Iconos de línea CS',
  'menu.cs-interface-icons': 'Iconos de interfaz CS',
  'menu.bootstrap-icons': 'Iconos de Bootstrap',
  'menu.tables': 'Tablas',
  'menu.typography': 'Tipografía',
  'menu.menu': 'Menú',
  'menu.horizontal': 'Horizontal',
  'menu.vertical': 'Vertical',
  'menu.vertical-hidden': 'Vertical oculto',
  'menu.vertical-no-hidden': 'Vertical no oculto',
  'menu.mobile-only': 'Solo móvil',
  'menu.sidebar': 'Barra lateral',
  'menu.Docs': 'Documentos',
  'menu.connections': 'Conexiones',
  'menu.bookmarks': 'Marcadores',
  'menu.requests': 'Solicitudes',
  'menu.account': 'Cuenta',
  'menu.password': 'Contraseña',
  'menu.devices': 'Dispositivos',
  'menu.notifications': 'Notificaciones',
  'menu.email': 'Correo electrónico',
  'menu.sms': 'SMS',
  'menu.downloads': 'Descargas',
  'menu.documents': 'Documentos',
  'menu.videos': 'Videos',
  'menu.dashboard': 'Dashboard',
  'menu.products': 'Productos',
  'menu.orders': 'Pedidos',
  'menu.customers': 'Clientes',
  'menu.storefront': 'Frente de tienda',
  'menu.filters': 'Filtros',
  'menu.categories': 'Categorías',
  'menu.cart': 'Carrito',
  'menu.checkout': 'Pagar',
  'menu.invoice': 'Factura',
  'menu.shipping': 'Envío',
  'menu.discount': 'Descuento',
  'menu.general': 'General',

  'menu.caja': 'Caja',
  'menu.cargo': 'Cargo',
  'menu.ingreso': 'Ingreso',

  'menu.upgrade': 'Actualizar',
  'menu.community': 'Comunidad',
  'menu.community-list': 'Lista de comunidad',
  'menu.getting-started': 'Empezando',
  'menu.analysis': 'Análisis',
  'menu.services': 'Servicios',
  'menu.database': 'Base de datos',
  'menu.database-add': 'Nueva base de datos',
  'menu.database-detail': 'Detalles de la base de datos',
  'menu.storage': 'Almacenamiento',
  'menu.hosting': 'Hospedaje',
  'menu.users': 'Usuarios',
  'menu.billing': 'Facturación',
  'menu.security': 'Seguridad',
  'menu.support': 'Soporte',
  'menu.docs': 'Documentos',
  'menu.docsdetail': 'Detalles de documentos',
  'menu.tickets': 'Boletos',
  'menu.tickets-detail': 'Detalles del boleto',

  'menu.elearning': 'Aprendizaje electrónico',
  'menu.school': 'Escuela',
  'menu.courses': 'Cursos',
  'menu.explore': 'Explorar',
  'menu.quiz': 'Examen',
  'menu.result': 'Resultado',
  'menu.paths': 'Caminos',
  'menu.instructor': 'Instructor',
  'menu.misc': 'Misceláneo',
  'menu.player': 'Reproductor',
  'menu.material': 'Material',
  'menu.syllabus': 'Sílabo',
};
export default enMessages;
