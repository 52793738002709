import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Card, Button, Col, Form, Row, Modal } from "react-bootstrap";
import Select from "react-select";
import { useHistory } from 'react-router-dom';
import HtmlHead from "components/html-head/HtmlHead";
import CsLineIcons from "cs-line-icons/CsLineIcons";
import regionesComunasJson from "regionesComunasJson";
import { addCliente } from "helper";

const CustomersAdd = ({
  clientes,
  pedidos,
  visitas,
  cotizaciones,
  codigos,
  db,
}) => {
  const title = "Crear Cliente";
  const description = "Crear Cliente";

  const history = useHistory();
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalCliente, setModalCliente] = useState();
  const [modalTittle, setModalTittle] = useState("");

  // Estado y funciones para el RUT
  const [rut, setRut] = useState("");
  const [isRutValid, setIsRutValid] = useState(false);

  const handleRutInputChange = (event) => {
    const inputValue = event.target.value.trim(); // Eliminar espacios en blanco alrededor
    setRut(inputValue);

    // Llama a la función de verificación del RUT
    const isValid = checkRutFormat(inputValue);
    setIsRutValid(isValid);
  };

  const checkRutFormat = (rut) => {
    // Expresión regular mejorada para verificar el formato del RUT chileno
    const rutRegex = /^(\d{1,3}(\.?\d{3})*)-(\d|K|k)$/;

    if (!rutRegex.test(rut)) {
      return false;
    }

    // Divide el RUT en el número y el dígito verificador
    const [numero, digitoVerificador] = rut.split("-");

    // Verifica el dígito verificador
    return (
      calculateVerificationDigit(numero) === digitoVerificador.toUpperCase()
    );
  };

  const calculateVerificationDigit = (numero) => {
    let suma = 0;
    let multiplicador = 2;

    // Calcula la suma ponderada del número del RUT
    for (let i = numero.length - 1; i >= 0; i--) {
      suma += parseInt(numero[i]) * multiplicador;
      multiplicador = multiplicador === 7 ? 2 : multiplicador + 1;
    }

    // Calcula el dígito verificador
    const resto = suma % 11;
    const dv = 11 - resto;
    console.log('😡 suma   ', suma)
    console.log('😡 resto  ', resto)
    console.log('😡 dv  ', dv)

    // Devuelve 'K' si el dígito verificador es 10, de lo contrario, devuelve el dígito verificador
    return dv === 10 ? "K": dv === 11 ? "0" : dv.toString();
  };

  // Estado y funciones para el número de teléfono
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const handlePhoneInputChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, ""); // Elimina todos los caracteres no numéricos
    setPhoneNumber(inputValue);

    // Llama a la función de verificación del número de teléfono
    const isValid = checkPhoneFormat(inputValue);
    setIsPhoneValid(isValid);
  };

  const checkPhoneFormat = (phoneNumber) => {
    // Expresión regular para verificar el formato del número de teléfono chileno
    const phoneRegex = /^9\d{8}$/;

    return phoneRegex.test(phoneNumber);
  };

  // Estado y funciones para el correo electrónico
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleEmailInputChange = (event) => {
    const inputValue = event.target.value.trim();
    setEmail(inputValue);

    // Llama a la función de verificación del correo electrónico
    const isValid = checkEmailFormat(inputValue);
    setIsEmailValid(isValid);
  };

  const checkEmailFormat = (email) => {
    // Expresión regular para verificar el formato del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const [selectValueRegion, setSelectValueRegion] = useState(null);
  const [selectValueComuna, setSelectValueComuna] = useState(null);
  const [optionsComuna, setOptionsComuna] = useState([]);

  const optionsRegion = regionesComunasJson.regiones.map((region) => ({
    value: region.region,
    label: region.region,
  }));

  const handleRegionChange = (selectedRegion) => {
    setSelectValueRegion(selectedRegion);

    // Filtrar las comunas para la región seleccionada
    const selectedRegionData = regionesComunasJson.regiones.find(
      (region) => region.region === selectedRegion.value
    );
    const comunasOptions = selectedRegionData
      ? selectedRegionData.comunas.map((comuna) => ({
          value: comuna,
          label: comuna,
        }))
      : [];
    setOptionsComuna(comunasOptions);

    // Limpiar la selección de la comuna
    setSelectValueComuna(null);
  };

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [isValidNombre, setIsValidNombre] = useState(true);
  const [isValidApellido, setIsValidApellido] = useState(true);

  const handleNombreChange = (event) => {
    const inputValue = event.target.value;
    setNombre(inputValue);
    setIsValidNombre(validateText(inputValue));
  };

  const handleApellidoChange = (event) => {
    const inputValue = event.target.value;
    setApellido(inputValue);
    setIsValidApellido(validateText(inputValue));
  };

  const validateText = (text) => {
    // La validación verifica que no esté vacío y solo contenga letras y espacios en blanco
    return /^[a-zA-Z\s]+$/.test(text) && text.trim().length > 0;
  };

  const [direccion, setDireccion] = useState("");
  const [isValidDireccion, setIsValidDireccion] = useState(true);

  const handleDireccionChange = (event) => {
    const inputValue = event.target.value;
    setDireccion(inputValue);
    setIsValidDireccion(inputValue.trim().length > 0);
  };

  const [isFormValid, setIsFormValid] = useState(false);

  // Función para verificar si todas las validaciones son true
  const checkFormValidity = () => {
    return (
      isPhoneValid &&
      isValidNombre &&
      isValidApellido &&
      isValidDireccion &&
      selectValueRegion !== null &&
      selectValueComuna !== null
    );
  };

  // Efecto para actualizar el estado isFormValid cuando cambian las validaciones
  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [
    isPhoneValid,
    isValidNombre,
    isValidApellido,
    isValidDireccion,
    selectValueRegion,
    selectValueComuna,
  ]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink
          className="muted-link pb-1 d-inline-block hidden breadcrumb-back"
          to="/dashboard"
        >
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Dashboard</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End */}

      <Row>
        <Col xs="12" className="col-lg order-1 order-lg-0">
          {/* Address Start */}
          <Card className="mb-5">
            <Card.Body>
              <Form>
                <Row className="g-3">
                  <Col lg="6">
                    <Row>
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control
                            type="text"
                            value={nombre}
                            onChange={handleNombreChange}
                            isInvalid={!isValidNombre}
                          />
                          {!isValidNombre && (
                            <Form.Control.Feedback type="invalid">
                              Nombre inválido
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>

                      <Col lg="6">
                        <Form.Group>
                          <Form.Label>Apellido</Form.Label>
                          <Form.Control
                            type="text"
                            value={apellido}
                            onChange={handleApellidoChange}
                            isInvalid={!isValidApellido}
                          />
                          {!isValidApellido && (
                            <Form.Control.Feedback type="invalid">
                              Apellido inválido
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label>RUT</Form.Label>
                          <Form.Control
                            type="text"
                            value={rut}
                            onChange={handleRutInputChange}
                            isInvalid={!isRutValid && rut.length > 0}
                          />
                          {!isRutValid && rut.length > 0 && (
                            <Form.Control.Feedback type="invalid">
                              RUT inválido
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label>Teléfono</Form.Label>
                          <Form.Control
                            type="text"
                            value={phoneNumber}
                            onChange={handlePhoneInputChange}
                            isInvalid={!isPhoneValid && phoneNumber.length > 0}
                          />
                          {!isPhoneValid && phoneNumber.length > 0 && (
                            <Form.Control.Feedback type="invalid">
                              Teléfono inválido
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            value={email}
                            onChange={handleEmailInputChange}
                            isInvalid={!isEmailValid && email.length > 0}
                          />
                          {!isEmailValid && email.length > 0 && (
                            <Form.Control.Feedback type="invalid">
                              Email inválido
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6">
                    <Col lg="12">
                      <Form.Group>
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control
                          type="text"
                          value={direccion}
                          onChange={handleDireccionChange}
                          isInvalid={!isValidDireccion}
                        />
                        {!isValidDireccion && (
                          <Form.Control.Feedback type="invalid">
                            La dirección no puede estar vacía.
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group>
                        <Form.Label>Región</Form.Label>
                        <Select
                          classNamePrefix="react-select"
                          options={optionsRegion}
                          value={selectValueRegion}
                          onChange={handleRegionChange}
                          placeholder=""
                        />
                      </Form.Group>
                    </Col>

                    <Col lg="12">
                      <Form.Group>
                        <Form.Label>Comuna</Form.Label>
                        <Select
                          classNamePrefix="react-select"
                          options={optionsComuna}
                          value={selectValueComuna}
                          onChange={setSelectValueComuna}
                          placeholder=""
                        />
                      </Form.Group>
                    </Col>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col lg="4">
                  <Button
                    className="btn-icon btn-icon-end w-100 mt-4"
                    variant="primary"
                    disabled={!isFormValid}
                    onClick={() => {
                      
                        var clickAddCliente = addCliente({
                          db: db,
                          codigos: codigos,
                          nombre: nombre,
                          apellido: apellido,
                          rut: rut,
                          telefono: phoneNumber,
                          email: email,
                          direccion: direccion,
                          region: selectValueRegion.value,
                          comuna: selectValueComuna.value,
                        });
                        if (clickAddCliente) {
                          console.log("EL USUARIO SE AGREGO CON EXITO");
                          history.push(`/customers/list`)
                        } else {
                          setModalTittle("ERROR AL AGREGAR AL CLIENTE");
                          setIsModalShow(true);
                          console.log("ERROR AL AGREGAR AL CLIENTE");
                        }
                      
                    }}
                  >
                    <span>Crear Cliente</span>{" "}
                    <CsLineIcons icon="chevron-right" />
                  </Button>
                </Col>
                <Col lg="4">
                  <Button
                    className="btn-icon btn-icon-end w-100 mt-4"
                    variant="primary"
                    disabled={!isFormValid}
                    onClick={async () => {
                      

                      

                      
                        var clickAddCliente = await addCliente({
                          db: db,
                          codigos: codigos,
                          nombre: nombre,
                          apellido: apellido,
                          rut: rut,
                          telefono: phoneNumber,
                          email: email,
                          direccion: direccion,
                          region: selectValueRegion.value,
                          comuna: selectValueComuna.value,
                        });
                        if (clickAddCliente !== false) {
                          console.log("EL USUARIO SE AGREGO CON EXITO");
                          history.push(`/visits/add?id=${clickAddCliente}`)
                        } else {
                          setModalTittle("ERROR AL AGREGAR AL CLIENTE");
                          setIsModalShow(true);
                          console.log("ERROR AL AGREGAR AL CLIENTE");
                        }
                      
                    }}
                  >
                    <span>Agendar Visita</span>{" "}
                    <CsLineIcons icon="chevron-right" />
                  </Button>
                </Col>
                <Col lg="4">
                  <Button
                    className="btn-icon btn-icon-end w-100 mt-4"
                    variant="primary"
                    disabled={!isFormValid}
                    onClick={async () => {
                      // const clienteEncontrado = clientes.find(
                      //   (clienteSearch) => clienteSearch.rut === rut
                      // );

                      

                        var clickAddCliente = await addCliente({
                          db: db,
                          codigos: codigos,
                          nombre: nombre,
                          apellido: apellido,
                          rut: rut,
                          telefono: phoneNumber,
                          email: email,
                          direccion: direccion,
                          region: selectValueRegion.value,
                          comuna: selectValueComuna.value,
                        });

                        if (clickAddCliente !== false) {

                          console.log("EL USUARIO SE AGREGO CON EXITO");
                          history.push(`/quotes/add?id=${clickAddCliente}`)
                        } else {
                          setModalTittle("ERROR AL AGREGAR AL CLIENTE");
                          setIsModalShow(true);
                          console.log("ERROR AL AGREGAR AL CLIENTE");
                        }
                      
                    }}
                  >
                    <span>Crear Cotizacion</span>{" "}
                    <CsLineIcons icon="chevron-right" />
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/* Address End */}
        </Col>
      </Row>

      {/* Categories Modal Start */}
      <Modal
        className="modal-center"
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
      >
        <Modal.Body closeButton>
        <Modal.Title style={{textAlignLast:'center'}} as="h5">{modalTittle}</Modal.Title>
        <Row>
          <Col lg="6">
          <Button
              className="btn-icon btn-icon-end w-100 mt-4"
              variant="primary"
              onClick={() => setIsModalShow(false)}
            >
              <span>Cerrar</span> <CsLineIcons icon="chevron-right" />
            </Button>
          </Col>
            
            {modalTittle === "EL CLIENTE YA EXISTE" ? (
              <Col lg="6">
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                variant="primary"
                onClick={() => history.push(`/customers/detail?id=${modalCliente.clienteId}`)}
              >
                <span>Ver Cliente</span> <CsLineIcons icon="chevron-right" />
              </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Modal.Body>
      </Modal>
      {/* Categories Modal End */}
    </>
  );
};

export default CustomersAdd;
