import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ReactTags from "react-tag-autocomplete";
import { Row, Col, Button, Dropdown, Card, Badge, Form, Modal } from "react-bootstrap";
import HtmlHead from "components/html-head/HtmlHead";
import CsLineIcons from "cs-line-icons/CsLineIcons";
import { convertirFormatoFechaCorto, deleteDocument } from "helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CustomersDetail = ({ db, clientes, pedidos, visitas, cotizaciones }) => {
  const history = useHistory();

  const title = "Detalle de Clientes";
  const description = "Detalle de Clientes";
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalTittle, setModalTittle] = useState("");

  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [cliente, setCliente] = useState(null);

  useEffect(() => {
    // Encuentra el cliente que coincida con el id
    console.log("cliente buscando 1");
    const clienteEncontrado = clientes.find(
      (clienteSearch) => clienteSearch.clienteId.toString() === id
    );

    // Actualiza el estado con el cliente encontrado
    setCliente(clienteEncontrado || clientes[clientes.length - 1]);

    // Coloca el código adicional que necesitas con el cliente encontrado aquí
  }, [clientes, id]);
  if (cliente === null) {
    return <div className="loading" />;
  }
  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink
              className="muted-link pb-1 d-inline-block hidden breadcrumb-back"
              to="/customers"
            >
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Clientes</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}
        </Row>
      </div>

      <Row>
        <Col xl="4">
          <h2 className="small-title">Cliente</h2>
          <Card className="mb-5">
            <Card.Body className="mb-n5">
              <div className="d-flex align-items-center flex-column mb-5">
                <div className="mb-5 d-flex align-items-center flex-column">
                  <div className="sw-6 sh-6 mb-3 d-inline-block bg-primary d-flex justify-content-center align-items-center rounded-xl">
                    <div className="text-white">{cliente.nombre.charAt(0)}{cliente.apellido.charAt(0)}</div>
                  </div>
                  <div className="h5 mb-1">{`${cliente.nombre != "" ? cliente.nombre : "- -"
                    } ${cliente.apellido != "" ? cliente.apellido : "- -"}`}</div>
                  <div className="text-muted">
                    <CsLineIcons icon="phone" className="me-1" />
                    <span className="align-middle">{`${cliente.telefono != "" ? cliente.telefono : "- -"
                      }`}</span>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between w-100 w-sm-50 w-xl-100">
                  <Button variant="primary" className="w-100 me-2" disabled>
                    Editar
                  </Button>
                  <Button variant="outline-primary" className="w-100 me-2" onClick={() => {

                    var clickDelete = deleteDocument({
                      db: db,
                      objeto: cliente,
                      documento: 'clientes'
                    });
                    if (clickDelete) {
                      console.log("EL CLIENTE SE ELIMINO CON EXITO");
                      history.push(`/customers/list`)
                    } else {
                      setModalTittle("ERROR AL ELIMINAR AL CLIENTE");
                      setIsModalShow(true);
                      console.log("ERROR AL ELIMINAR AL CLIENTE");
                    }

                  }}>
                    Eliminar
                  </Button>
                </div>
              </div>
              
              <div style={{ marginBottom: "4.125rem" }}>
                <p className="text-small text-muted mb-2">Datos</p>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="user"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${cliente.nombre != "" ? cliente.nombre : "- -"
                    } ${cliente.apellido != "" ? cliente.apellido : "- -"}`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="credit-card"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${cliente.rut != "" ? cliente.rut : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="pin"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${cliente.direccion != "" ? cliente.direccion : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="email"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${cliente.email != "" ? cliente.email : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="phone"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${cliente.telefono != "" ? cliente.telefono : "- -"
                    }`}</Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xl="8">
          {/* Recent Visits Start */}
          <h2 className="small-title">Visitas</h2>
          {visitas
            .slice()
            .sort((a, b) => b.visitaId - a.visitaId)
            .filter((visita) => visita.clienteId === cliente.clienteId)
            .slice(0, 5)
            .map((visita) => {
              const clienteVisita = clientes.find(
                (clienteSearch) =>
                  clienteSearch.clienteId.toString() ===
                  visita?.clienteId.toString()
              );
              // if (clienteVisita? === null) {
              //   return <div className="loading"/>
              // }
              return (
                <Card className="mb-2 sh-15 sh-md-6">
                  <Card.Body className="pt-0 pb-0 h-100">
                    <Row className="g-0 h-100 align-content-center">
                      <Col
                        xs="10"
                        md="2"
                        className="d-flex align-items-center mb-3 mb-md-0 h-md-100"
                      >
                        <NavLink
                          to={`/visits/detail?id=${visita?.clienteId}`}
                          className="body-link stretched-link"
                        >
                          Visita #{visita?.visitaId}
                        </NavLink>
                      </Col>
                      <Col
                        xs="12"
                        md="4"
                        className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                      >
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {`${clienteVisita?.nombre != ""
                            ? clienteVisita?.nombre
                            : "- -"
                            } ${clienteVisita?.apellido != ""
                              ? clienteVisita?.apellido
                              : "- -"
                            }`}
                        </span>
                      </Col>
                      <Col
                        xs="12"
                        md="4"
                        className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                      >
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {`${visita?.direccion != "" ? visita?.direccion : "- -"}`}
                        </span>
                      </Col>
                      <Col
                        xs="12"
                        md="2"
                        className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                      >
                        <span>
                          {`${visita?.fechaVisita != '' ? convertirFormatoFechaCorto((visita?.fechaVisita).toDate()) : '- -'}`}
                        </span>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
          {/* Recent Visits End */}

          {/* Recent Quotes Start */}
          <h2 className="small-title">Cotizaciones</h2>
          {cotizaciones
            .slice()
            .sort((a, b) => b.cotizacionId - a.cotizacionId)
            .filter((cotizacion) => cotizacion.clienteId === cliente.clienteId)
            .slice(0, 5)
            .map((cotizacion) => {
              const clienteCotizacion = clientes.find(
                (clienteSearch) =>
                  clienteSearch.clienteId.toString() ===
                  cotizacion?.clienteId.toString()
              );

              return (
                <Card className="mb-2 sh-15 sh-md-6">
                  <Card.Body className="pt-0 pb-0 h-100">
                    <Row className="g-0 h-100 align-content-center">
                      <Col
                        xs="10"
                        md="2"
                        className="d-flex align-items-center mb-3 mb-md-0 h-md-100"
                      >
                        <NavLink
                          to={`/quotes/detail?id=${cotizacion?.cotizacionId}`}
                          className="body-link stretched-link"
                        >
                          Cotización #{cotizacion?.cotizacionId}
                        </NavLink>
                      </Col>
                      <Col
                        xs="12"
                        md="4"
                        className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                      >
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {`${clienteCotizacion?.nombre != ""
                            ? clienteCotizacion?.nombre
                            : "- -"
                            } ${clienteCotizacion?.apellido != ""
                              ? clienteCotizacion?.apellido
                              : "- -"
                            }`}
                        </span>
                      </Col>
                      <Col
                        xs="12"
                        md="3"
                        className="d-flex align-items-center justify-content-md-start mb-1 mb-md-0 text-alternate"
                      >


                        {`${cotizacion?.fechaCotizacion != '' ? convertirFormatoFechaCorto((cotizacion?.fechaCotizacion).toDate()) : '- -'}`}
                      </Col>
                      <Col
                        xs="2"
                        md="3"
                        className="d-flex align-items-center text-muted mb-1 mb-md-0 justify-content-end justify-content-md-start"
                      >
                        <Badge bg="outline-primary" className="me-1">
                          {`${cotizacion?.estado != "" ? cotizacion?.estado : "- -"
                            }`}
                        </Badge>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
          {/* Recent Quotes End */}

          {/* Recent Orders Start */}
          <h2 className="small-title">Pedidos</h2>
          {pedidos
            .slice()
            .sort((a, b) => b.pedidoId - a.pedidoId)
            .filter((pedido) => pedido.clienteId === cliente.clienteId)
            .slice(0, 5)
            .map((pedido) => {
              const clientePedido = clientes.find(
                (clienteSearch) =>
                  clienteSearch.clienteId.toString() ===
                  pedido?.clienteId.toString()
              );

              return (
                <Card className="mb-2 sh-15 sh-md-6">
                  <Card.Body className="pt-0 pb-0 h-100">
                    <Row className="g-0 h-100 align-content-center">
                      <Col
                        xs="10"
                        md="2"
                        className="d-flex align-items-center mb-3 mb-md-0 h-md-100"
                      >
                        <NavLink
                          to={`/orders/detail?id=${pedido?.pedidoId}`}
                          className="body-link stretched-link"
                        >
                          Pedido #{pedido?.pedidoId}
                        </NavLink>
                      </Col>
                      <Col
                        xs="12"
                        md="4"
                        className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                      >
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          {`${clientePedido?.nombre != ""
                            ? clientePedido?.nombre
                            : "- -"
                            } ${clientePedido?.apellido != ""
                              ? clientePedido?.apellido
                              : "- -"
                            }`}
                        </span>
                      </Col>
                      <Col
                        xs="12"
                        md="3"
                        className="d-flex align-items-center justify-content-md-start mb-1 mb-md-0 text-alternate"
                      >


                        {`${pedido?.fecha.entrega != '' ? convertirFormatoFechaCorto((pedido?.fecha.entrega).toDate()) : '- -'}`}
                      </Col>
                      <Col
                        xs="2"
                        md="3"
                        className="d-flex align-items-center text-muted mb-1 mb-md-0 justify-content-end justify-content-md-start"
                      >
                        <Badge bg="outline-primary" className="me-1">
                          {`${pedido?.estado != "" ? pedido?.estado : "- -"
                            }`}
                        </Badge>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
          {/* Recent Orders End */}
        </Col>
      </Row>
      {/* Categories Modal Start */}
      <Modal
        className="modal-center"
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
      >
        <Modal.Body closeButton>
          <Modal.Title style={{ textAlignLast: 'center' }} as="h5">{modalTittle}</Modal.Title>
          <Row>
            <Col lg="6">
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                variant="primary"
                onClick={() => setIsModalShow(false)}
              >
                <span>Cerrar</span> <CsLineIcons icon="chevron-right" />
              </Button>
            </Col>

          </Row>
        </Modal.Body>
      </Modal>
      {/* Categories Modal End */}
    </>
  );
};

export default CustomersDetail;
