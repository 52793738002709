import React, { memo, lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import Dashboard from "./views/dashboard/Dashboard";
import OrdersList from "./views/orders/list/OrdersList";
import OrdersDetail from "./views/orders/detail/OrdersDetail";
import CustomersList from "./views/customers/list/CustomersList";
import CustomersDetail from "./views/customers/detail/CustomersDetail";
import QuotesList from "views/quotes/list/QuotesList";
import QuotesDetail from "views/quotes/detail/QuotesDetail";
import VisitsList from "views/visits/list/VisitsList";
import CustomersAdd from "views/customers/add/CustomersAdd";
import VisitsAdd from "views/visits/add/VisitsAdd";
import QuotesAdd from "views/quotes/add/QuotesAdd";

import CajaList from "./views/caja/list/CajaList";
import CajaCargo from "views/caja/add/CajaCargo";
import CajaIngreso from "views/caja/add/CajaIngreso";


const dashboard = lazy(() => import("./views/dashboard/Dashboard"));
const ordersList = lazy(() => import("./views/orders/list/OrdersList"));
const ordersDetail = lazy(() => import("./views/orders/detail/OrdersDetail"));
const customersList = lazy(() =>
  import("./views/customers/list/CustomersList")
);
const ocustomersDetail = lazy(() =>
  import("./views/customers/detail/CustomersDetail")
);

const RouteItem = ({
  redirect = false,
  exact = false,
  path,
  to,
  component,
  clientes,
  caja,
  pedidos,
  visitas,
  cotizaciones,
  codigos,
  db
}) => {

  if (!path && !to) {
    return () => {};
  }
  if (redirect) {
    const props = {};
    if (path) props.from = path;
    if (exact) props.exact = exact;
    if (to) props.to = to;
    return <Redirect {...props} />;
  }
  const props = {};
  if (path) props.path = path;
  if (exact) props.exact = exact;

  switch (component) {
    case "dashboard":
      return (
        <Route
          {...props}
          component={() => (
            <Dashboard
              clientes={clientes}
              caja={caja}
              pedidos={pedidos}
              visitas={visitas}
              cotizaciones={cotizaciones}
              codigos={codigos}
              db={db}
            />
          )}
        />
      );
    case "ordersList":
      return (
        <Route
          {...props}
          component={() => (
            <OrdersList
              clientes={clientes}
              pedidos={pedidos}
              visitas={visitas}
              cotizaciones={cotizaciones}
              codigos={codigos}
              db={db}
            />
          )}
        />
      );
    case "ordersDetail":
      return (
        <Route
          {...props}
          component={() => (
            <OrdersDetail
              clientes={clientes}
              pedidos={pedidos}
              visitas={visitas}
              cotizaciones={cotizaciones}
              codigos={codigos}
              db={db}
            />
          )}
        />
      );

      case "cajaList":
        return (
          <Route
            {...props}
            component={() => (
              <CajaList
                clientes={clientes}
                cajas={caja}
                pedidos={pedidos}
                visitas={visitas}
                cotizaciones={cotizaciones}
                codigos={codigos}
                db={db}
              />
            )}
          />
        );
        case "cajaCargo":
          return (
            <Route
              {...props}
              component={() => (
                <CajaCargo
                  clientes={clientes}
                  cajas={caja}
                  pedidos={pedidos}
                  visitas={visitas}
                  cotizaciones={cotizaciones}
                  codigos={codigos}
                  db={db}
                  
                />
              )}
            />
          );
          case "cajaIngreso":
          return (
            <Route
              {...props}
              component={() => (
                <CajaIngreso
                  clientes={clientes}
                  cajas={caja}
                  pedidos={pedidos}
                  visitas={visitas}
                  cotizaciones={cotizaciones}
                  codigos={codigos}
                  db={db}
                  
                />
              )}
            />
          );
      
    case "customersList":
      return (
        <Route
          {...props}
          component={() => (
            <CustomersList
              clientes={clientes}
              pedidos={pedidos}
              visitas={visitas}
              cotizaciones={cotizaciones}
              codigos={codigos}
              db={db}
            />
          )}
        />
      );
    case "customersDetail":
      return (
        <Route
          {...props}
          component={() => (
            <CustomersDetail
              clientes={clientes}
              pedidos={pedidos}
              visitas={visitas}
              cotizaciones={cotizaciones}
              codigos={codigos}
              db={db}
            />
          )}
        />
      );
      case "customersAdd":
        return (
          <Route
            {...props}
            component={() => (
              <CustomersAdd
                clientes={clientes}
                pedidos={pedidos}
                visitas={visitas}
                cotizaciones={cotizaciones}
                codigos={codigos}
                db={db}
                
              />
            )}
          />
        );
    case "quotesList":
      return (
        <Route
          {...props}
          component={() => (
            <QuotesList
              clientes={clientes}
              pedidos={pedidos}
              visitas={visitas}
              cotizaciones={cotizaciones}
              codigos={codigos}
              db={db}
            />
          )}
        />
      );
    case "quotesDetail":
      return (
        <Route
          {...props}
          component={() => (
            <QuotesDetail
              clientes={clientes}
              pedidos={pedidos}
              visitas={visitas}
              cotizaciones={cotizaciones}
              codigos={codigos}
              db={db}
            />
          )}
        />
      );
      case "quotesAdd":
        return (
          <Route
            {...props}
            component={() => (
              <QuotesAdd
                clientes={clientes}
                pedidos={pedidos}
                visitas={visitas}
                cotizaciones={cotizaciones}
                codigos={codigos}
                db={db}
              />
            )}
          />
        );
    case "visitsList":
      return (
        <Route
          {...props}
          component={() => (
            <VisitsList
              clientes={clientes}
              pedidos={pedidos}
              visitas={visitas}
              cotizaciones={cotizaciones}
              codigos={codigos}
              db={db}
            />
          )}
        />
      );
      case "visitsAdd":
        return (
          <Route
            {...props}
            component={() => (
              <VisitsAdd
                clientes={clientes}
                pedidos={pedidos}
                visitas={visitas}
                cotizaciones={cotizaciones}
                codigos={codigos}
                db={db}
              />
            )}
          />
        );

    default:
      if (component) props.component = component;
      return <Route {...props} />;
  }
};

export default memo(RouteItem);
