import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import {
  Card,
  Button,
  Col,
  Form,
  Row,
  FormControl,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import HtmlHead from "components/html-head/HtmlHead";
import CsLineIcons from "cs-line-icons/CsLineIcons";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { addCotizacion, convertirFormatoFechaAlternativo, formatNumberToPesos } from "helper";
import { coloresCubiertaPiedra, coloresCubiertaPostformado } from "coloresCubiertas";
import { coloresInteriorExterior } from "coloresMelamina";

registerLocale("es", es);
const QuotesAdd = ({
  clientes,
  pedidos,
  visitas,
  cotizaciones,
  codigos,
  db,
}) => {
  const history = useHistory();
  const location = useLocation();
  const title = "Agregar Cotización";
  const description = "Cotización";
  const id = new URLSearchParams(location.search).get("id");
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalTittle, setModalTittle] = useState("");
  const [items, setItems] = useState([]);
  const [extras, setExtras] = useState([]);
  const [incluyeIva, setIncluyeIva] = useState({ label: "Si", value: true });

  const [clienteEncontrado, setClienteEncontrado] = useState(
    clientes.find(
      (clienteSearch) =>
        clienteSearch.clienteId.toString() === clientes[0].clienteId.toString()
    )
  );

  useEffect(() => {
    // Encuentra el cotizacion que coincida con el id
    if (id) {
      const clienteEnviado = clientes.find(
        (clienteSearch) =>
          clienteSearch.clienteId.toString() === id.toString()
      );

      setClienteEncontrado(clienteEnviado)
    }
  }, [id]);

  const handleClienteChange = (event) => {
    // Cuando se selecciona un cliente, actualiza el esta

    setClienteEncontrado(event);
  };

  const [ganancia, setGanancia] = useState(0);
  const [descuento, setDescuento] = useState(0);

  const handleValueChange = (value, setValue) => {
    // Asegurarse de que el valor esté en el rango de 0 a 100
    const numericValue =
      value === "" ? "" : Math.min(100, Math.max(0, parseInt(value, 10)));
    setValue(isNaN(numericValue) ? "" : numericValue.toString());
    updateValues();
  };

  const handleIncluyeIvaChange = (event) => {
    setIncluyeIva(event);
    updateValues();
  };

  const handleDescuentoChange = (event) => {
    handleValueChange(event.target.value, setDescuento);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isValidDate, setIsValidDate] = useState(true);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    // Validar si la fecha está vacía
    setIsValidDate(!!date);
  };



  const tiposDeMueble = [
    { label: "Mueble Base", value: "mueble_base", precio: 120000 },
    { label: "Mueble Aéreo 60cm alto", value: "mueble_aereo_60", precio: 90000 },
    { label: "Mueble Aéreo 70cm alto", value: "mueble_aereo_70", precio: 100000 },
    { label: "Mueble Aéreo 80cm alto", value: "mueble_aereo_80", precio: 110000 },
    { label: "Mueble Torre", value: "mueble_torre", precio: 155000 },
    {
      label: "Cubierta Postformado",
      value: "cubierta_postformado",
      precio: 90000,
      cubierta: true,
    },
    {
      label: "Cubierta Piedra",
      value: "cubierta_piedra",
      precio: 220000,
      cubierta: true,
    },
    { label: "Mueble Lavaplatos", value: "mueble_lavaplatos", precio: 73000 },
    { label: "Mueble Despensa", value: "mueble_despensa", precio: 180000 },
    { label: "Mueble Vanitorio", value: "mueble_vanitorio", precio: 120000 },
    { label: "Mueble Escritorio", value: "mueble_escritorio", precio: 70000 },
    { label: "Mueble Velador", value: "mueble_velador", precio: 150000 },
    { label: "Mueble Librero", value: "mueble_librero", precio: 110000 },
    { label: "Mueble Rack", value: "mueble_rack", precio: 95000 },
    { label: "Mueble Closet", value: "mueble_closet", precio: 250000 },
    { label: "Mueble Closet Estandard", value: "mueble_mini_closet", precio: 190000 },
    { label: "Mueble Repisa Baño", value: "mueble_repisa_bano", precio: 90000 },
    { label: "Cortes Blancos M2", value: "cortes_blancos", precio: 20000 },
    { label: "Cortes Color M2", value: "cortes_color", precio: 30000 },
    { label: "Otro", value: "otro" },
  ];

  const tiposDeExtra = [
    {
      label: "Cajon Chico Cierre Simple",
      value: "cajon_chico_cierre_simple",
      precio: 20000,
    },
    {
      label: "Cajon Grande Cierre Simple",
      value: "cajon_grande_cierre_simple",
      precio: 30000,
    },
    {
      label: "Cajon Chico Cierre Suave",
      value: "cajon_chico_cierre_suave",
      precio: 30000,
    },
    {
      label: "Cajon Grande Cierre Suave",
      value: "cajon_grande_cierre_suave",
      precio: 40000,
    },
    { label: "Vidrio M2", value: "vidrio", precio: 1000 },
    { label: "Espejo M2", value: "espejo", precio: 1000 },
    { label: "Especiero", value: "especiero", precio: 67000 },
    { label: "Cesta 40cm", value: "cesta_40", precio: 83000 },
    { label: "Cesta 45cm", value: "cesta_45", precio: 85000 },
    { label: "Cesta 60cm", value: "cesta_60", precio: 93000 },
    { label: "Cerradura", value: "cerradura", precio: 5000 },
  ];

  const [selectedTipoMueble, setSelectedTipoMueble] = useState(null);
  const [otrosPrecio, setOtrosPrecio] = useState("");
  const [otrosNombre, setOtrosNombre] = useState("");

  const handleTipoMuebleChange = (selectedOption) => {
    setSelectedTipoMueble(selectedOption);
    // Restablecer el valor de precio cuando se cambia el tipo de mueble
    setOtrosPrecio("");
    setOtrosNombre("");
  };

  const handleOtrosPrecioChange = (event) => {
    setOtrosPrecio(event.target.value);
  };

  const handleOtrosNombreChange = (event) => {
    setOtrosNombre(event.target.value);
  };

  const [medidas, setMedidas] = useState("");
  const [isValidMedidas, setIsValidMedidas] = useState(false);

  const handleMedidasChange = (event) => {
    const inputValue = event.target.value.trim();

    // Utilizar expresiones regulares para validar si el valor es un número positivo (puede ser float)
    const isValidInput =
      /^[+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*)(?:[eE][+-]?\d+)?$/.test(inputValue);

    // Actualizar el estado de medidas y isValidMedidas en una sola línea
    isValidInput ? setMedidas(inputValue) : setMedidas(""); // Puedes manejar el caso no válido según tus necesidades
    setIsValidMedidas(isValidInput);
  };

  const [cantidadMueble, setCantidadMueble] = useState(1);
  const handleCantidadMuebleChange = (event) => {
    setCantidadMueble(event.target.value);
  };

  const [precioMueble, setPrecioMueble] = useState("");
  const handlePrecioMuebleChange = (event) => {
    setPrecioMueble(event.target.value);
    if (event.target.value !== "") {
      setIsValidMedidas(true);
    } else {
      setIsValidMedidas(false);

    }
  };

  const [selectedColorCubierta, setSelectedColorCubierta] = useState(null);
  const [selectedColorInterior, setSelectedColorInterior] = useState(null);
  const [selectedColorExterior, setSelectedColorExterior] = useState(null);
  const [selectedManillas, setSelectedManillas] = useState(null);

  const manillas = [
    { label: "No Aplica", value: "no_aplica" },
    { label: "Metalicas", value: "metalicas" },
    { label: "Plasticas", value: "plasticas" },
    { label: "Tirador", value: "tirador" },
    { label: "Sistema Gola", value: "sistema_gola" },
    // Agrega más colores según sea necesario
  ];


  const [detalle, setDetalle] = useState("");

  const handleDetalleChange = (e) => {
    const inputValue = e.target.value;
    setDetalle(inputValue);
  };
  const [isFormValid, setIsFormValid] = useState(false);

  const handleAgregarItem = () => {

    var medidasMueble = medidas === "" ? 1 : medidas

    // Verificar si es "Otros" y asignar el precio adecuado
    const precio =
     medidas === "" ? precioMueble :selectedTipoMueble && selectedTipoMueble.value === "otro"
          ? otrosPrecio || 0
          : selectedTipoMueble?.precio || 0;

    const precioTotal = Math.round(
      cantidadMueble * (medidasMueble * precio +
        extras.reduce((acc, extra) => {
          return acc + extra.cantidad * extra.tipo.precio;
        }, 0))
    );

    // Crear el nuevo item
    const newItem = {
      id: new Date().getTime(), // Asignar un ID único basado en el timestamp
      tipoMueble:
        selectedTipoMueble.value === "otro"
          ? otrosNombre
          : selectedTipoMueble.label,
      otrosPrecio,
      otrosNombre,
      cantidadMueble,
      precioMueble,
      medidas: medidasMueble,
      selectedColorCubierta,
      selectedColorInterior,
      selectedColorExterior,
      selectedManillas,
      detalle,
      precio,
      extras,
      precioTotal,
    };

    console.log("newItem", newItem);

    // Agregar el nuevo item al estado
    setItems([...items, newItem]);

    // Limpiar los campos después de agregar el item
    setExtras([]);
    setSelectedTipoMueble(null);
    setOtrosPrecio("");
    setSelectedColorCubierta(null);
    setSelectedColorInterior(null);
    setSelectedColorExterior(null);
    setSelectedManillas(null);
    setMedidas("");
    setDetalle("");
    setPrecioMueble("");
    setCantidadMueble(1);
    setIsValidMedidas(true);
    updateValues();
  };

  const handleAgregarExtra = () => {
    // Agregar un nuevo "extra" con propiedades "detalle", "tipo" y "cantidad" inicializadas
    setExtras([...extras, { detalle: "", tipo: null, cantidad: 0 }]);
  };

  const handleTipoExtraChange = (selectedOption, index) => {
    // Actualizar el "tipo" del "extra" en la posición index
    const updatedExtras = [...extras];
    updatedExtras[index].tipo = selectedOption;
    setExtras(updatedExtras);
  };

  const handleCantidadChange = (index, value) => {
    // Actualizar la "cantidad" del "extra" en la posición index
    const updatedExtras = [...extras];
    updatedExtras[index].cantidad = parseInt(value, 10) || 0;
    setExtras(updatedExtras);
  };

  const handleDetalleExtraChange = (index, value) => {
    // Actualizar el "detalle" del "extra" en la posición index
    const updatedExtras = [...extras];
    updatedExtras[index].detalle = value;
    setExtras(updatedExtras);
  };

  const handleEliminarItem = (itemId) => {
    // Filtrar los items para excluir el item con el ID proporcionado
    const updatedItems = items.filter((item) => item.id !== itemId);
    setItems(updatedItems);
    updateValues();
  };

  const [neto, setNeto] = useState(0);
  const [descuentoDetalle, setDescuentoDetalle] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);

  const updateValues = () => {
    const sumaItems = items
      .map((item) => item.precioTotal)
      .reduce((total, multiplicacion) => total + multiplicacion, 0);
    const descuentoItems = Math.round(sumaItems * (descuento / 100));
    const ivaItems = incluyeIva.value === true ? Math.round((sumaItems - descuentoItems) * (19 / 100)) : 0;
    const totalItems = Math.round(sumaItems - descuentoItems + ivaItems);
    console.log('😡 ', ivaItems, incluyeIva)
    setNeto(sumaItems);
    setDescuentoDetalle(descuentoItems);
    setIva(ivaItems);
    setTotal(totalItems);
  };

  useEffect(() => {
    let isValidColors = false;

    if (selectedTipoMueble !== null && selectedTipoMueble.cubierta === true) {
      isValidColors = selectedColorCubierta !== null;
    } else if (
      selectedTipoMueble !== null &&
      selectedTipoMueble.cubierta === undefined
    ) {
      isValidColors =
        selectedColorInterior !== null && selectedColorExterior !== null;
    }

    setIsFormValid(
      isValidColors && selectedManillas !== null,
      selectedManillas !== null &&
      (selectedTipoMueble.value !== "otro" ||
        (otrosPrecio.trim() !== "" &&
          !isNaN(otrosPrecio) &&
          otrosNombre !== "")) && (precioMueble === "" ? isValidMedidas : true)
    );
  }, [
    selectedColorCubierta,
    selectedColorInterior,
    selectedColorExterior,
    selectedManillas,
    selectedTipoMueble,
    isValidMedidas,
    precioMueble,
    otrosPrecio,
    otrosNombre,
  ]);

  useEffect(() => {
    updateValues();
  }, [items, descuento, incluyeIva]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink
          className="muted-link pb-1 d-inline-block hidden breadcrumb-back"
          to="/dashboard"
        >
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Dashboard</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End */}

      <Row>
        <Col lg="7" className="col-lg order-0 order-lg-0">
          {/* Address Start */}
          <h2 className="small-title">Cotización</h2>
          <Card className="mb-5">
            <Card.Body>
              <Form>
                <Row className="g-3">
                  <Col lg="12" className="mt-2">
                    <Form.Group>
                      <Form.Label>Cliente</Form.Label>

                      <Select
                        options={clientes}
                        value={clienteEncontrado}
                        onChange={handleClienteChange}
                        isSearchable
                        placeholder="Selecciona un cliente"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" className="mt-2">
                    <Form.Group>
                      <Form.Label>IVA</Form.Label>
                      <Select
                        options={[{ label: "Si", value: true },
                        { label: "No", value: false },]}
                        value={incluyeIva}
                        onChange={handleIncluyeIvaChange}
                        isSearchable
                        placeholder="¿Se incluye IVA?"
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="6" className="mt-2">
                    <Form.Group>
                      <Form.Label>Descuento</Form.Label>
                      <div className="input-group">
                        <Form.Control
                          type="text"
                          value={descuento}
                          onChange={handleDescuentoChange}
                          className="form-control"
                        />
                        <div className="input-group-append"></div>
                        <span className="input-group-text">%</span>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="6" className="mt-2">
                    <Form.Group>
                      <Form.Label>Fecha</Form.Label>
                      <DatePicker
                        locale="es"
                        className={`form-control`}
                        selected={selectedDate}
                        onChange={handleDateSelect}
                        dateFormat="dd/MM/yyyy"
                      />
                      {!isValidDate && (
                        <Form.Control.Feedback type="invalid">
                          La fecha no puede estar vacía.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Row>
                    <Col lg="6" className="mt-2">
                      <Form.Group>
                        <Form.Label>Tipo de mueble</Form.Label>
                        <Select
                          options={tiposDeMueble}
                          value={selectedTipoMueble}
                          onChange={handleTipoMuebleChange}
                          isSearchable
                          placeholder="Selecciona un tipo de mueble"
                        />
                      </Form.Group>

                      {selectedTipoMueble &&
                        selectedTipoMueble.value === "otro" && (
                          <FormGroup>
                            <Form.Label>Nombre para "Otro"</Form.Label>
                            <FormControl
                              type="text"
                              value={otrosNombre}
                              onChange={handleOtrosNombreChange}
                              placeholder="Ingrese el nombre"
                            />
                            <Form.Label>Precio para "Otro"</Form.Label>
                            <FormControl
                              type="number"
                              value={otrosPrecio}
                              onChange={handleOtrosPrecioChange}
                              placeholder="Ingrese el precio"
                            />
                          </FormGroup>
                        )}
                    </Col>

                    <Col lg="6" className="mt-2">
                      <Form.Group>
                        <Form.Label>Cantidad</Form.Label>
                        <div className="input-group">
                          <FormControl
                            type="number"
                            value={cantidadMueble}
                            onChange={handleCantidadMuebleChange}
                            placeholder="Ingrese la cantidad"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6" className="mt-2">
                      <Form.Group>
                        <Form.Label>Precio</Form.Label>
                        <div className="input-group">

                          <span className="input-group-text">$</span>
                          <div className="input-group-append"></div>
                          <FormControl
                            type="number"
                            value={precioMueble}
                            onChange={handlePrecioMuebleChange}
                            placeholder="Ingrese el precio"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col lg="6" className="mt-2">
                      <Form.Group>
                        <Form.Label>Medidas</Form.Label>
                        <div className="input-group">
                          <FormControl
                            type="text"
                            value={medidas}
                            onChange={handleMedidasChange}
                            isInvalid={!isValidMedidas}
                            placeholder="Ingrese las medidas"
                          />

                          <div className="input-group-append"></div>
                          <span className="input-group-text">Metros</span>
                          {!isValidMedidas && (
                            <Form.Control.Feedback type="invalid">
                              Ingrese un valor válido.
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    



                    <Col lg="6" className="mt-2">
                      <Form.Label>Manillas</Form.Label>
                      <Select
                        options={manillas}
                        value={selectedManillas}
                        onChange={(value) => setSelectedManillas(value)}
                        isSearchable
                        placeholder="Selecciona el tipo de manillas"
                      />
                    </Col>

                    {selectedTipoMueble &&
                      selectedTipoMueble.cubierta === true && (
                        <Col lg="6" className="mt-2">
                          <Form.Label>Color Cubierta</Form.Label>
                          <Select
                            options={
                              selectedTipoMueble.value ===
                                "cubierta_postformado"
                                ? coloresCubiertaPostformado
                                : selectedTipoMueble.value === "cubierta_piedra"
                                  ? coloresCubiertaPiedra
                                  : []
                            }
                            value={selectedColorCubierta}
                            onChange={(value) =>
                              setSelectedColorCubierta(value)
                            }
                            isSearchable
                            placeholder="Selecciona un color"
                          />
                        </Col>
                      )}
                    {selectedTipoMueble &&
                      selectedTipoMueble.cubierta === undefined && (
                        <React.Fragment>
                          <Col lg="6" className="mt-2">
                            <Form.Label>Color interior</Form.Label>
                            <Select
                              options={coloresInteriorExterior}
                              value={selectedColorInterior}
                              onChange={(value) =>
                                setSelectedColorInterior(value)
                              }
                              isSearchable
                              placeholder="Selecciona un color"
                            />
                          </Col>
                          <Col lg="6" className="mt-2">
                            <Form.Label>Color exterior</Form.Label>
                            <Select
                              options={coloresInteriorExterior}
                              value={selectedColorExterior}
                              onChange={(value) =>
                                setSelectedColorExterior(value)
                              }
                              isSearchable
                              placeholder="Selecciona un color"
                            />
                          </Col>
                        </React.Fragment>
                      )}

                    <Col lg="12" className="mt-2">
                      <Form.Group>
                        <Form.Label>Detalle</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={detalle}
                          onChange={handleDetalleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {extras.map((extra, index) => (
                    <Row className="mt-2" key={index}>
                      <h2 className="small-title">Extra {index + 1}</h2>

                      <Col lg="6" className="mt-2">
                        <Form.Group>
                          <Form.Label>Tipo de mueble</Form.Label>
                          <Select
                            options={tiposDeExtra}
                            value={extra.tipo}
                            onChange={(selectedOption) =>
                              handleTipoExtraChange(selectedOption, index)
                            }
                            isSearchable
                            placeholder="Selecciona un tipo de extra"
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="6" className="mt-2">
                        <Form.Group>
                          <Form.Label>Cantidad</Form.Label>
                          <Form.Control
                            type="number"
                            value={extra.cantidad}
                            onChange={(e) =>
                              handleCantidadChange(index, e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col lg="12" className="mt-2">
                        <Form.Group>
                          <Form.Label>Detalle Extra</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            value={extra.detalle}
                            onChange={(e) =>
                              handleDetalleExtraChange(index, e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ))}

                  <Row>
                    <Col lg="3">
                      <Button
                        className="btn-icon btn-icon-end w-100 mt-4"
                        variant="primary"
                        onClick={handleAgregarExtra}
                      >
                        <span>Agregar extra</span>
                      </Button>
                    </Col>
                  </Row>
                  <Col lg="12">
                    <Button
                      className="btn-icon btn-icon-end w-100 mt-4"
                      variant="primary"
                      onClick={handleAgregarItem}
                      disabled={!isFormValid}
                    >
                      <span>Agregar item</span>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          {/* Address End */}
        </Col>
        <Col lg="5" className="order-1 order-lg-2">
          {/* Cart Start */}
          <h2 className="small-title">Detalle</h2>
          <Card className="mb-5">
            <Card.Body>
              <div className="mb-5">
                <p className="text-small text-muted mb-2">Datos</p>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="user"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado
                    ? clienteEncontrado.nombre != ""
                      ? clienteEncontrado.nombre
                      : "- -"
                    : "- -"
                    } ${clienteEncontrado
                      ? clienteEncontrado.apellido != ""
                        ? clienteEncontrado.apellido
                        : "- -"
                      : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="credit-card"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado
                    ? clienteEncontrado.rut != ""
                      ? clienteEncontrado.rut
                      : "- -"
                    : "- -"
                    }`}</Col>
                </Row>

                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="pin"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado
                    ? clienteEncontrado.direccion != ""
                      ? clienteEncontrado.direccion
                      : "- -"
                    : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="email"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado
                    ? clienteEncontrado.email != ""
                      ? clienteEncontrado.email
                      : "- -"
                    : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="phone"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado
                    ? clienteEncontrado.telefono != ""
                      ? clienteEncontrado.telefono
                      : "- -"
                    : "- -"
                    }`}</Col>
                </Row>
              </div>
              <div className="mb-5">
                <p className="text-small text-muted mb-2">Datos Cotizacion</p>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="calendar"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">
                    Fecha{" "}
                    {`${selectedDate
                      ? convertirFormatoFechaAlternativo(selectedDate)
                      : "- -"
                      }`}
                  </Col>
                </Row>
              </div>
              <div className="mb-4">
                <Row className="mb-4">
                  <Col>
                    <div className="ps-4 pt-0 pb-2 pe-0 h-100">
                      {items.map((item) => (
                        <Row className="g-0 pb-2 align-items-start align-content-center">
                          <Col xs="1">
                            <div
                              className="sw-3 me-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleEliminarItem(item.id)}
                            >
                              <CsLineIcons
                                icon="bin"
                                size="17"
                                className="text-primary"
                              />
                            </div>
                          </Col>
                          <Col xs="11" className="d-flex flex-column mb-2">
                            <div>{item.tipoMueble || "N/A"}</div>
                            {item.selectedColorCubierta !== null ? (
                              <div className="text-muted text-small">
                                Color Cubierta{" "}
                                {item.selectedColorCubierta.label || " "}
                              </div>
                            ) : (
                              <></>
                            )}
                            {item.selectedColorExterior !== null &&
                              item.selectedColorInterior !== null ? (
                              item.selectedColorExterior.label ===
                                item.selectedColorInterior.label ? (
                                <div className="text-muted text-small">
                                  Color {item.selectedColorExterior.label}
                                </div>
                              ) : (
                                <>
                                  <div className="text-muted text-small">
                                    Color Interior{" "}
                                    {item.selectedColorInterior.label}
                                  </div>
                                  <div className="text-muted text-small">
                                    Color Exterior{" "}
                                    {item.selectedColorExterior.label}
                                  </div>
                                </>
                              )
                            ) : (
                              <></>
                            )}
                            {
                              item.selectedManillas.label !== 'No Aplica' ?
                                <div className="text-muted text-small">
                                  Manillas {item.selectedManillas.label}
                                </div> : <></>
                            }
                            <div className="text-muted text-small">
                              {item.detalle || " "}
                            </div>
                            {item.extras.map((extra) => (
                              <div key={uuidv4()}>
                                <div className="text-small">
                                  {extra.tipo.label || " "}
                                </div>
                                <div style={{ marginLeft: "0.75rem" }}>
                                  <div className=" text-muted text-small">
                                    {extra.detalle || " "}
                                  </div>
                                  <Row className="g-0">
                                    <Col
                                      xs="6"
                                      className="d-flex flex-row pe-2 align-items-end text-alternate"
                                    >
                                      <span>
                                        <span className="text-small">
                                          {extra.cantidad}
                                        </span>
                                      </span>
                                      <span className="text-muted ms-1 me-1">
                                        x
                                      </span>
                                      <span>
                                        <span className="text-small">
                                          ${formatNumberToPesos(extra.tipo.precio)}
                                        </span>
                                      </span>
                                    </Col>
                                    <Col
                                      xs="6"
                                      className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                    >
                                      <span>
                                        <span className="text-small">
                                          ${formatNumberToPesos(Math.round(extra.cantidad * extra.tipo.precio))}
                                        </span>
                                      </span>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ))}
                          </Col>
                          <Col
                            xs="12"
                            className="d-flex flex-column mb-md-0 pt-1"
                          >
                            {
                              item.precioMueble !== "" ?

                                <Row className="g-0">
                                  <Col
                                    xs="6"
                                    className="d-flex flex-row pe-2 align-items-end text-alternate"
                                  >
                                    <span>
                                      
                                    </span>
                                  </Col>
                                  <Col
                                    xs="6"
                                    className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                  >
                                    <span>
                                      <span className="text-small">
                                       {item.cantidadMueble} x ${formatNumberToPesos(Math.round(item.medidas * item.precio))}
                                      </span>
                                    </span>
                                  </Col>
                                </Row>
                                :
                                <Row className="g-0">
                                  <Col
                                    xs="6"
                                    className="d-flex flex-row pe-2 align-items-end text-alternate"
                                  >
                                    <span>{item.medidas} metros</span>
                                    <span className="text-muted ms-1 me-1">x</span>
                                    <span>
                                      <span className="text-small">
                                      ${formatNumberToPesos(item.precio)} metro lineal
                                      </span>
                                    </span>
                                  </Col>
                                  <Col
                                    xs="6"
                                    className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                  >
                                    <span>
                                      <span className="text-small">
                                        {item.cantidadMueble} x ${formatNumberToPesos(Math.round(item.medidas * item.precio))}
                                      </span>
                                    </span>
                                  </Col>
                                </Row>
                            }
                            <Row className="g-0">
                              <Col
                                xs="6"
                                className="d-flex flex-row pe-2 align-items-end text-alternate"
                              >
                                <span>
                                  Precio total por {item.cantidadMueble} {item.tipoMueble || " "}
                                </span>
                              </Col>
                              <Col
                                xs="6"
                                className="d-flex flex-row align-items-end justify-content-end text-alternate"
                              >
                                <span>
                                  <span className="text-small">
                                    ${formatNumberToPesos(item.precioTotal)}
                                  </span>
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <Row className="g-0 mb-2">
                  <Col xs="auto" className="ms-auto ps-3 text-muted">
                    Neto
                  </Col>
                  <Col xs="auto" className="sw-13 text-end">
                    <span>
                      <span className="text-small text-muted">${formatNumberToPesos(neto)}</span>
                      {/* {pedido.detalles.reduce((acum, item) => acum + item.total, 0)} */}
                    </span>
                  </Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto" className="ms-auto ps-3 text-muted">
                    Descuento
                  </Col>
                  <Col xs="auto" className="sw-13 text-end">
                    <span>
                      <span className="text-small text-muted">
                        ${formatNumberToPesos(descuentoDetalle)}
                      </span>
                      {/* {pedido.detalles.reduce((acum, item) => acum + item.total, 0)} */}
                    </span>
                  </Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto" className="ms-auto ps-3 text-muted">
                    IVA
                  </Col>
                  <Col xs="auto" className="sw-13 text-end">
                    <span>
                      <span className="text-small text-muted">${formatNumberToPesos(iva)}</span>
                      {/* {(pedido.detalles.reduce((acum, item) => acum + item.total, 0))*0.19} */}
                    </span>
                  </Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto" className="ms-auto ps-3 text-muted">
                    Total
                  </Col>
                  <Col xs="auto" className="sw-13 text-end">
                    <span>
                      <span className="text-small text-muted">${formatNumberToPesos(total)}</span>
                      {/* {((pedido.detalles.reduce((acum, item) => acum + item.total, 0))+ ((pedido.detalles.reduce((acum, item) => acum + item.total, 0))*0.19))} */}
                    </span>
                  </Col>
                </Row>
              </div>
              <Col lg="12">
                <Button
                  className="btn-icon btn-icon-end w-100 mt-4"
                  variant="primary"
                  onClick={() => {
                    var clickAddCotizacion = addCotizacion({
                      db: db,
                      codigos: codigos,
                      clienteCotizacion: clienteEncontrado,
                      fechaCotizacion: selectedDate,
                      neto: neto,
                      descuento: descuentoDetalle,
                      iva: iva,
                      total: total,
                      items: items,
                    });
                    if (clickAddCotizacion) {
                      console.log("LA COTIZACION SE AGREGO CON EXITO");
                      history.push(`/quotes/list`);
                    } else {
                      setModalTittle("ERROR AL AGREGAR LA COTIZACION");
                      setIsModalShow(true);
                      console.log("ERROR AL AGREGAR LA COTIZACION");
                    }
                  }}
                  disabled={items.length === 0}
                >
                  <span>Agregar Cotizacion</span>
                </Button>
              </Col>
            </Card.Body>
          </Card>
          {/* Cart End */}
        </Col>
      </Row>
      {/* Categories Modal Start */}
      <Modal
        className="modal-center"
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
      >
        <Modal.Body closeButton>
          <Modal.Title style={{ textAlignLast: "center" }} as="h5">
            {modalTittle}
          </Modal.Title>
          <Row>
            <Col lg="6">
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                variant="primary"
                onClick={() => setIsModalShow(false)}
              >
                <span>Cerrar</span> <CsLineIcons icon="chevron-right" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Categories Modal End */}
    </>
  );
};

export default QuotesAdd;
