export const coloresInteriorExterior = [
  { label: "Coigüe Clásico", value: "coigue_clasico" },
  { label: "Chocolate Clásico", value: "chocolate_clasico" },
  { label: "Cerezo Clásico", value: "cerezo_clasico" },
  { label: "Peral Clásico", value: "peral_clasico" },
  { label: "Cedro Clásico", value: "cedro_clasico" },

  { label: "Carbón Unicolor", value: "carbon_unicolor" },
    { label: "Himalaya Unicolor", value: "himalaya_unicolor" },
    { label: "Humo Unicolor", value: "humo_unicolor" },
    { label: "Gris Humo Unicolor", value: "gris_humo_unicolor" },
    { label: "Negro Unicolor", value: "negro_unicolor" },
    { label: "Almendra Unicolor", value: "almendra_unicolor" },
    { label: "Rojo Unicolor", value: "rojo_unicolor" },
    { label: "Blanco Unicolor", value: "blanco_unicolor" },
    { label: "Grafito Unicolor", value: "grafito_unicolor" },
    { label: "Taupe Unicolor", value: "taupe_unicolor" },

    { label: "Seda Giorno Textil", value: "seda_giorno_textil" },
    { label: "Seda Notte Textil", value: "seda_notte_textil" },
    { label: "Lino Chiaro Textil", value: "lino_chiaro_textil" },
    { label: "Blanco Hilado Textil", value: "blanco_hilado_textil" },

    { label: "Antalya Marmoleado", value: "antalya_marmoleado" },
    { label: "Mármara Marmoleado", value: "marmara_marmoleado" },
    { label: "Vizcaya Marmoleado", value: "vizcaya_marmoleado" },

    { label: "Jerez Maderado", value: "jerez_maderado" },
    { label: "Espresso Maderado", value: "espresso_maderado" },
    { label: "Roble Provenzal Maderado", value: "roble_provenzal_maderado" },
    { label: "Caramel Maderado", value: "caramel_maderado" },
    { label: "Roble Rústico Maderado", value: "roble_rustico_maderado" },
    { label: "Legno Maderado", value: "legno_maderado" },
    { label: "Nogal Terracota Maderado", value: "nogal_terracota_maderado" },
    { label: "Cendra Escandinavo Maderado", value: "cendra_escandinavo_maderado" },
    { label: "Teka Ártico Maderado", value: "teka_artico_maderado" },
    { label: "Roble Cava Maderado", value: "roble_cava_maderado" },
    { label: "Toscana Maderado", value: "toscana_maderado" },
    { label: "Awoura Maderado", value: "awoura_maderado" },
    { label: "Alaska Maderado", value: "alaska_maderado" },
    { label: "Aserrado Nórdico Maderado", value: "aserrado_nordico_maderado" },
    { label: "Origen Andino Maderado", value: "origen_andino_maderado" },
    { label: "Nodo Maderado", value: "nodo_maderado" },
  { label: "Okuzai Maderado", value: "okuzai_maderado" },

    { label: "Titanio Metalizado", value: "titanio_metalizado" },
    { label: "Platino Metalizado", value: "platino_metalizado" },

    { label: "Nougat Synchro", value: "nougat_synchro" },
    { label: "Cocoa Synchro", value: "cocoa_synchro" },
    // Agrega más colores según sea necesario
  ];