import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Row, Col, Button, Dropdown, Card, Modal, Form } from "react-bootstrap";
import HtmlHead from "components/html-head/HtmlHead";
import generatePDF from "react-to-pdf";
import Select from "react-select";
import CsLineIcons from "cs-line-icons/CsLineIcons";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import {
  addAbono,
  addPedido,
  convertirFormatoFechaAlternativo,
  convertirFormatoFechaCorto,
  deleteDocument,
  formatNumberToPesos,
  sumarDiasHabiles,
  updatePedidoStatus,
} from "helper";
import { object } from "yup";
import { PEDIDO_STATUS } from "constants";

registerLocale("es", es);


const OrdersDetail = ({ db, codigos, clientes, pedidos, visitas, cotizaciones }) => {
  const title = "Detalles de Pedido";
  const description = "Detalles de Pedido";
  const history = useHistory();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalTittle, setModalTittle] = useState("");
  const [isModalTwoShow, setIsModalTwoShow] = useState(false);
  const [modalTwoTittle, setModalTwoTittle] = useState("");
  const [pedido, setPedido] = useState(null);
  const [clienteEncontrado, setClienteEncontrado] = useState(null);

  const [montoAbono, setMontoAbono] = useState(0);
  const [tipoAbono, setTipoAbono] = useState({ label: "Transferencia", value: 'transferencia' });
  const [ultimoPago, setUltimoPago] = useState(null);
  const [sumaMontos, setSumaMontos] = useState(0);



  // const ultimoPago = pedido.pagos.length > 0 ? pedido.pagos[pedido.pagos.length - 1] : null;
  // const sumaMontos = pedido.pagos.reduce((acumulador, pago) => acumulador + parseFloat(pago.monto), 0);


  useEffect(() => {
    if (pedido !== null ||
      pedido !== undefined) {
      console.log('pedido', pedido)
      
      setUltimoPago(pedido?.pagos?.length > 0 ? pedido.pagos[pedido.pagos.length - 1] : null)
      setSumaMontos(pedido?.pagos?.reduce((acumulador, pago) => acumulador + parseFloat(pago.monto), 0))
    }

  }, [pedido]);


  const handleMontoAbonoChange = (event) => {
    setMontoAbono(event.target.value);
    // updateValues();
  };

  const handleTipoAbonoChange = (event) => {
    setTipoAbono(event);
    // updateValues();
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isValidDate, setIsValidDate] = useState(true);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    // Validar si la fecha está vacía
    setIsValidDate(!!date);
  };


  const targetRef = useRef();

  useEffect(async () => {
    console.log('clientes : ', clientes)
    // Encuentra el cotizacion que coincida con el id
    const pedidoEcontrado = await pedidos.find(
      (pedidoSearch) => {
        console.log('pedido search: ', pedidoSearch.pedidoId)
        return (pedidoSearch.pedidoId.toString() === id)
      }
    );

    console.log('pedido ', pedidoEcontrado)
    const p = pedidoEcontrado || pedidos[pedidos.length - 1];
    console.log("p ", p);
    // Actualiza el estado con el cotizacion encontrado
    setPedido(p);

    if (p !== undefined) {
      const c = clientes.find(
        (clienteSearch) =>
          clienteSearch.clienteId.toString() === p.clienteId.toString()
      );

      setClienteEncontrado(c);
    }

    // Coloca el código adicional que necesitas con el cotizacion encontrado aquí
  }, [pedidos, id]);


  console.log('ESTE ES EL PEDIDO', pedido)

  if (
    pedido === null ||
    pedido === undefined ||
    clienteEncontrado === null ||
    clienteEncontrado === undefined
  ) {
    return <div className="loading" />;
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink
              className="muted-link pb-1 d-inline-block hidden breadcrumb-back"
              to="/orders"
            >
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Pedidos</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}

          {/* Top Buttons Start */}
          {pedido !== undefined ? (
            <Col
              xs="12"
              sm="auto"
              className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3"
            >
              <Button onClick={() => setIsModalShow(true)}>
                Agregar abono
              </Button>
              <Dropdown className=" ms-1 w-100 w-md-auto">
                <Dropdown.Toggle className="w-100 w-md-auto" variant="primary">
                  Status: {pedido.estado}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    PEDIDO_STATUS.map((pedidoStatus) => (
                      <Dropdown.Item
                        onClick={()=> {
                          var clickUpdate = updatePedidoStatus({db, pedido, newStatus: pedidoStatus})
                          if (clickUpdate) {
                            console.log("EL STATUS SE ACTUALIZO CON EXITO");
                          } else {
                            setModalTwoTittle("ERROR AL ACTUALIZAR EL STATUS");
                            setIsModalTwoShow(true);
                            console.log("ERROR AL ACTUALIZAR EL STATUS");
                          }
                        }}
                      >{`Status: ${pedidoStatus}`}</Dropdown.Item>
                    ))
                  }
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="ms-1">
                <Dropdown.Toggle
                  className="btn-icon btn-icon-only dropdown-toggle-no-arrow"
                  variant="outline-primary"
                >
                  <CsLineIcons icon="more-horizontal" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Edit</Dropdown.Item>
                  <Dropdown.Item onClick={() => {

                    var clickDelete = deleteDocument({
                      db: db,
                      objeto: pedido,
                      documento: 'pedidos'
                    });
                    if (clickDelete) {
                      console.log("EL PEDIDO SE ELIMINO CON EXITO");
                      history.push(`/orders/list`)
                    } else {
                      setModalTittle("ERROR AL ELIMINAR EL PEDIDO");
                      setIsModalShow(true);
                      console.log("ERROR AL ELIMINAR EL PEDIDO");
                    }

                  }}>Eliminar</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          ) : (
            <></>
          )}
          {/* Top Buttons End */}
        </Row>
      </div>

      <Row>
        <Col xl="8" xxl="8">
          {pedido !== undefined ? (
            <>
              <h2 className="small-title">Pedido</h2>
              <Card className="mb-5" ref={targetRef}>
                <Card.Body>
                  <div className="mb-5">
                    <div className="mb-4">
                      <Row className="mb-4">
                        <Col>
                          <div className="ps-4 pt-0 pb-2 pe-0 h-100">
                            {pedido.items !== "" ? (
                              pedido.items.map((item) => (
                                <Row key={uuidv4()} className="g-0 pb-2 align-items-start align-content-center">
                                  <Col
                                    xs="12"
                                    className="d-flex flex-column mb-2"
                                  >
                                    <div>{item.tipoMueble || "N/A"}</div>
                                    {item.selectedColorCubierta !== null ? (
                                      <div className="text-muted text-small">
                                        Color Cubierta{" "}
                                        {item.selectedColorCubierta.label ||
                                          " "}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    {item.selectedColorExterior !== null &&
                                      item.selectedColorInterior !== null ? (
                                      item.selectedColorExterior.label ===
                                        item.selectedColorInterior.label ? (
                                        <div className="text-muted text-small">
                                          Color{" "}
                                          {item.selectedColorExterior.label}
                                        </div>
                                      ) : (
                                        <>
                                          <div className="text-muted text-small">
                                            Color Interior{" "}
                                            {item.selectedColorInterior.label}
                                          </div>
                                          <div className="text-muted text-small">
                                            Color Exterior{" "}
                                            {item.selectedColorExterior.label}
                                          </div>
                                        </>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    <div className="text-muted text-small">
                                      Manillas{" "}
                                      {item.selectedManillas?.label}
                                    </div>

                                    <div className="text-muted text-small">
                                      {item.detalle || " "}
                                    </div>
                                    {item.extras.map((extra) => (
                                      <div key={uuidv4()}>
                                        <div className="text-small">
                                          {extra.tipo.label || " "}
                                        </div>
                                        <div style={{ marginLeft: "0.75rem" }}>
                                          <div className=" text-muted text-small">
                                            {extra.detalle || " "}
                                          </div>
                                          <Row className="g-0">
                                            <Col
                                              xs="6"
                                              className="d-flex flex-row pe-2 align-items-end text-alternate"
                                            >
                                              <span>
                                                <span className="text-small">
                                                  {extra.cantidad}
                                                </span>
                                              </span>
                                              <span className="text-muted ms-1 me-1">
                                                x
                                              </span>
                                              <span>
                                                <span className="text-small">
                                                  ${formatNumberToPesos(extra.tipo.precio)}
                                                </span>
                                              </span>
                                            </Col>
                                            <Col
                                              xs="6"
                                              className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                            >
                                              <span>
                                                <span className="text-small">
                                                  $
                                                  {formatNumberToPesos(Math.round(extra.cantidad *
                                                    extra.tipo.precio))}
                                                </span>
                                              </span>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    ))}
                                  </Col>
                                  <Col
                                    xs="12"
                                    className="d-flex flex-column mb-md-0 pt-1"
                                  >
                                    <Row className="g-0">
                                      <Col
                                        xs="6"
                                        className="d-flex flex-row pe-2 align-items-end text-alternate"
                                      >
                                        <span>{item.medidas} metros</span>
                                        <span className="text-muted ms-1 me-1">
                                          x
                                        </span>
                                        <span>
                                          <span className="text-small">
                                            ${formatNumberToPesos(item.precio)} metro lineal
                                          </span>
                                        </span>
                                      </Col>
                                      <Col
                                        xs="6"
                                        className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                      >
                                        <span>
                                          <span className="text-small">
                                            ${formatNumberToPesos(Math.round(item.medidas * item.precio))}
                                          </span>
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row className="g-0">
                                      <hr
                                        className="w-100 mb-0 mt-2"
                                        style={{ opacity: "0.25" }}
                                      />
                                      <Col
                                        xs="6"
                                        className="d-flex flex-row pe-2 align-items-end text-alternate"
                                      >
                                        <span>
                                          Precio total{" "}
                                          {item.tipoMueble || "N/A"}
                                        </span>
                                      </Col>
                                      <Col
                                        xs="6"
                                        className="d-flex flex-row align-items-end justify-content-end text-alternate"
                                      >
                                        <span>
                                          <span>${formatNumberToPesos(Math.round(item.precioTotal))}</span>
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row className="g-0 mb-2">
                        <Col xs="auto" className="ms-auto ps-3 ">
                          Neto
                        </Col>
                        <Col xs="auto" className="sw-13 text-end">
                          <span>
                            <span className="text-small ">
                              ${formatNumberToPesos(Math.round(pedido.valor.neto))}
                            </span>
                            {/* {pedido.detalles.reduce((acum, item) => acum + item.total, 0)} */}
                          </span>
                        </Col>
                      </Row>
                      <Row className="g-0 mb-2">
                        <Col xs="auto" className="ms-auto ps-3">
                          Descuento
                        </Col>
                        <Col xs="auto" className="sw-13 text-end">
                          <span>
                            <span className="text-small ">
                              ${formatNumberToPesos(Math.round(pedido.valor.descuento))}
                            </span>
                            {/* {pedido.detalles.reduce((acum, item) => acum + item.total, 0)} */}
                          </span>
                        </Col>
                      </Row>
                      <Row className="g-0 mb-2">
                        <Col xs="auto" className="ms-auto ps-3 ">
                          IVA
                        </Col>
                        <Col xs="auto" className="sw-13 text-end">
                          <span>
                            <span className="text-small ">
                              ${formatNumberToPesos(Math.round(pedido.valor.iva))}
                            </span>
                            {/* {(pedido.detalles.reduce((acum, item) => acum + item.total, 0))*0.19} */}
                          </span>
                        </Col>
                      </Row>
                      <Row className="g-0 mb-2">
                        <Col
                          xs="auto"
                          className="ms-auto ps-3 font-weight-bold"
                        >
                          Total
                        </Col>
                        <Col
                          xs="auto"
                          className="sw-13 text-end font-weight-bold"
                        >
                          <span>
                            <span>${formatNumberToPesos(Math.round(pedido.valor.total))}</span>
                            {/* {((pedido.detalles.reduce((acum, item) => acum + item.total, 0))+ ((pedido.detalles.reduce((acum, item) => acum + item.total, 0))*0.19))} */}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          ) : (
            <></>
          )}
        </Col>

        <Col xl="4" xxl="4">
          {/* Address Start */}
          <h2 className="small-title">Cliente</h2>
          <Card className="mb-5">
            <Card.Body className="mb-n5">
              <div className="mb-5">
                <p className="text-small text-muted mb-2">Datos</p>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="user"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.nombre != ""
                    ? clienteEncontrado.nombre
                    : "- -"
                    } ${clienteEncontrado.apellido != ""
                      ? clienteEncontrado.apellido
                      : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="credit-card"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.rut != "" ? clienteEncontrado.rut : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="pin"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.direccion != ""
                    ? clienteEncontrado.direccion
                    : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="at-sign"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.email != ""
                    ? clienteEncontrado.email
                    : "- -"
                    }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="phone"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${clienteEncontrado.telefono != ""
                    ? clienteEncontrado.telefono
                    : "- -"
                    }`}</Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
          {/* Address End */}
          {/* Status Start */}
          <h2 className="small-title">Detalles</h2>
          <Row className="g-2 mb-5">
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="tag" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Id
                      </div>
                      <div className="text-primary">
                        {pedido.pedidoId}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons
                          icon="clipboard"
                          className="text-primary"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Estado
                      </div>
                      <div className="text-primary">{`${pedido.estado != "" ? pedido.estado : "- -"
                        }`}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="dollar" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Último Abono
                      </div>
                      <div className="text-primary">
                        {`$${ultimoPago ? formatNumberToPesos(ultimoPago.monto) : 0}`}
                      </div>
                      <div className="text-primary">
                        {`${ultimoPago ? ultimoPago.tipoAbono : "- -"}`}
                      </div>
                      <div className="text-primary">
                        {`${ultimoPago ? convertirFormatoFechaAlternativo(ultimoPago.fecha.toDate()) : "- -"}`}
                      </div>
                      
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="money" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Saldo
                      </div>
                      <div className="text-primary">
                        {`$${formatNumberToPesos(Math.round(pedido.valor.total - sumaMontos))}`}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="calendar" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Fecha Inicio
                      </div>
                      <div className="text-primary">{`${pedido.fecha.inicio != ""
                        ? convertirFormatoFechaAlternativo(
                          pedido.fecha.inicio.toDate()
                        )
                        : "- -"
                        }`}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="calendar" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">
                        Fecha Entrega
                      </div>
                      <div className="text-primary">{`${pedido.fecha.entrega != ""
                        ? convertirFormatoFechaAlternativo(
                          pedido.fecha.entrega.toDate()
                        )
                        : "- -"
                        }`}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* Status End */}
        </Col>
      </Row>
      {/* Categories Modal Start */}
      <Modal
        className="modal-right"
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
      >
        <Modal.Body closeButton>
          <Modal.Title style={{ textAlignLast: "center" }} as="h5">
            Agregar abono
          </Modal.Title>
          <Row className="mt-4">
            <Col lg="12">
              <Form.Group>
                <Form.Label>Monto</Form.Label>
                <div className="input-group">

                  <span className="input-group-text">$</span>
                  <div className="input-group-append"></div>
                  <Form.Control
                    type="number"
                    value={montoAbono}
                    onChange={handleMontoAbonoChange}
                    className="form-control"
                  />

                </div>
              </Form.Group>

            </Col>

          </Row>
          <Row className="mt-4">
            <Col lg="12">
              <Form.Group>
                <Form.Label>Tipo de Abono</Form.Label>
                <Select
                  options={[{ label: "Transferencia", value: 'transferencia' },
                  { label: "Efectivo", value: 'efectivo' },
                  { label: "Cheque", value: 'cheque' },
                  { label: "TransBank", value: 'transbank' },
                  ]}
                  value={tipoAbono}
                  onChange={handleTipoAbonoChange}
                  isSearchable
                  placeholder="Seleccione el tipo de abono"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="12">
              <Form.Group>
                <Form.Label>Fecha</Form.Label>
                <DatePicker
                  locale="es"
                  className={`form-control`}
                  selected={selectedDate}
                  onChange={handleDateSelect}
                  dateFormat="dd/MM/yyyy"
                />
                {!isValidDate && (
                  <Form.Control.Feedback type="invalid">
                    La fecha no puede estar vacía.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="12">
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                variant="primary"
                onClick={() => {
                  addAbono({ db: db, pedido: pedido, monto: montoAbono, fecha: selectedDate, tipoAbono: tipoAbono.value });
                  setIsModalShow(false);
                }}
                disabled={montoAbono <= 0}
              >
                <span>Agregar</span> <CsLineIcons icon="chevron-right" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>


      <Modal
        className="modal-center"
        show={isModalTwoShow}
        onHide={() => setIsModalTwoShow(false)}
      >
        <Modal.Body closeButton>
        <Modal.Title style={{textAlignLast:'center'}} as="h5">{modalTwoTittle}</Modal.Title>
        <Row>
          <Col lg="6">
          <Button
              className="btn-icon btn-icon-end w-100 mt-4"
              variant="primary"
              onClick={() => setIsModalTwoShow(false)}
            >
              <span>Cerrar</span> <CsLineIcons icon="chevron-right" />
            </Button>
          </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Categories Modal End */}

    </>
  );
};

export default OrdersDetail;
