import { baseDatos } from "constants";
import { collection, doc, addDoc, setDoc, deleteDoc } from "firebase/firestore";



export const obtenerColorBadge = ({ estado }) => {
  switch (estado) {
    case 'Creado':
      return 'outline-info'
    case 'Dimensionar':
      return 'outline-warning'
    case 'Pedido':
      return 'outline-success'
    case 'Instalar':
      return 'outline-danger'
    case 'Finalizado':
      return 'outline-secondary'
    case 'Pendiente':
      return 'outline-primary'
    default:
      return 'outline-info'
  }
}


export const updatePedidoStatus = async ({
  db,
  pedido,
  newStatus
}) => {

  try {
    const dashboardRef = collection(db, "dashboard");
    // Intentar actualizar el código de cliente en la baseDatos
    await setDoc(
      doc(dashboardRef, baseDatos, "pedidos", pedido.id),
      { estado: newStatus },
      { merge: true }
    );

    // Si ambas operaciones tienen éxito, retornar true
    return true;
  } catch (error) {
    // Si hay un error en alguna de las operaciones, retornar false
    console.error("Error al actualizar el status:", error);
    return false;
  }

}


export const addCaja = async ({
  db,
  codigos,
  nombre,
  valor,
  tipoDePago,
  fecha,
  detalle,
  tipo,
  codigo = ''
  
}) => {
  try {
    const dashboardRef = collection(db, "dashboard");

    const cargoData = {
      cajaId: codigos.codCaja + 1,
      nombre: nombre,
      valor: valor,
      tipoDePago:tipoDePago,
      fecha: fecha,
      detalle: detalle,
      codigo:codigo,
      tipo: tipo
    };

    // Intentar agregar el cliente
    const cargoRef = await addDoc(
      collection(dashboardRef, baseDatos, "caja"),
      cargoData
    );

    // Intentar actualizar el código de caja en la baseDatos
    await setDoc(
      doc(dashboardRef, baseDatos),
      { codCaja: codigos.codCaja + 1 },
      { merge: true }
    );

    // Si ambas operaciones tienen éxito, retornar true
    return codigos.codCaja + 1;
  } catch (error) {
    // Si hay un error en alguna de las operaciones, retornar false
    console.error("Error al agregar cargo:", error);
    return false;
  }
};



export const addCliente = async ({
  db,
  codigos,
  nombre,
  apellido,
  rut,
  telefono,
  email,
  direccion,
  region,
  comuna,
}) => {
  try {
    const dashboardRef = collection(db, "dashboard");
    const setDirection =
      region === "Región Metropolitana de Santiago"
        ? `${direccion}, ${comuna}`
        : `${direccion}, ${comuna}, ${region}`;

    const clienteData = {
      clienteId: codigos.codCliente + 1,
      nombre: nombre,
      apellido: apellido,
      rut: rut,
      telefono: telefono,
      email: email,
      direccion: setDirection,
    };

    // Intentar agregar el cliente
    const clienteRef = await addDoc(
      collection(dashboardRef, baseDatos, "clientes"),
      clienteData
    );

    // Intentar actualizar el código de cliente en la baseDatos
    await setDoc(
      doc(dashboardRef, baseDatos),
      { codCliente: codigos.codCliente + 1 },
      { merge: true }
    );

    // Si ambas operaciones tienen éxito, retornar true
    return codigos.codCliente + 1;
  } catch (error) {
    // Si hay un error en alguna de las operaciones, retornar false
    console.error("Error al agregar cliente:", error);
    return false;
  }
};


export const addVisita = async ({ db, codigos, clienteVisita, detalle, fechaVisita }) => {
  try {

    const dashboardRef = collection(db, "dashboard");
    const visitaData = {
      visitaId: codigos.codVisita + 1,
      clienteId: clienteVisita.clienteId,
      detalle: detalle,
      direccion: clienteVisita.direccion,
      fechaVisita: fechaVisita,
    };

    // Intentar agregar el cliente
    const visitaRef = await addDoc(
      collection(dashboardRef, baseDatos, "visitas"),
      visitaData
    );

    // Intentar actualizar el código de cliente en la baseDatos
    await setDoc(
      doc(dashboardRef, baseDatos),
      { codVisita: codigos.codVisita + 1 },
      { merge: true }
    );

    // Si ambas operaciones tienen éxito, retornar true
    return true;
  } catch (error) {
    // Si hay un error en alguna de las operaciones, retornar false
    console.error("Error al agregar la visita:", error);
    return false;
  }
}

export const addCotizacion = async ({ db, codigos, clienteCotizacion, fechaCotizacion, neto, descuento, iva, total, items }) => {
  try {

    const dashboardRef = collection(db, "dashboard");
    const cotizacionData = {
      cotizacionId: codigos.codCotizacion + 1,
      clienteId: clienteCotizacion.clienteId,
      fechaCotizacion,
      estado: 'creada',
      pedidoId: null,
      items,
      valor: {
        neto,
        iva,
        descuento,
        total
      }
    };

    // Intentar agregar el cliente
    const visitaRef = await addDoc(
      collection(dashboardRef, baseDatos, "cotizaciones"),
      cotizacionData
    );


    // Intentar actualizar el código de cliente en la baseDatos
    await setDoc(
      doc(dashboardRef, baseDatos),
      { codCotizacion: codigos.codCotizacion + 1 },
      { merge: true }
    );

    // Si ambas operaciones tienen éxito, retornar true
    return true;
  } catch (error) {
    // Si hay un error en alguna de las operaciones, retornar false
    console.error("Error al agregar la cotizacion:", error);
    return false;
  }
}

export const addPedido = async ({ db, codigos, clientePedido, pedido }) => {
  try {

    const dashboardRef = collection(db, "dashboard");
    const fechaInicio = new Date();
    const pedidoData = {
      pedidoId: codigos.codPedido + 1,
      clienteId: clientePedido.clienteId,
      cotizacionId: pedido.cotizacionId,
      fecha: {
        entrega: sumarDiasHabilesTypeDate(fechaInicio, 20),
        inicio: fechaInicio
      },
      pagos: [],
      estado: 'creado',
      items: pedido.items,
      valor: {
        descuento: pedido.valor.descuento,
        neto: pedido.valor.neto,
        iva: pedido.valor.iva,
        total: pedido.valor.total,
      }
    };

    // Intentar agregar el cliente
    const pedidoRef = await addDoc(
      collection(dashboardRef, baseDatos, "pedidos"),
      pedidoData
    );


    // Intentar actualizar el código de cliente en la baseDatos
    await setDoc(
      doc(dashboardRef, baseDatos),
      { codPedido: codigos.codPedido + 1 },
      { merge: true }
    );

    // Si ambas operaciones tienen éxito, retornar true
    return true;
  } catch (error) {
    // Si hay un error en alguna de las operaciones, retornar false
    console.error("Error al agregar el pedido:", error);
    return false;
  }
}

export const deleteDocument = async ({ db, objeto, documento }) => {
  try {

    const dashboardRef = collection(db, "dashboard", baseDatos, `${documento}`);

    // Intentar eliminar el cliente
    const deleteRef = await deleteDoc(doc(dashboardRef, `${objeto.id}`));

    // Si la operacion tiene éxito, retornar true
    return true;
  } catch (error) {
    // Si hay un error en alguna de las operaciones, retornar false
    console.error(`Error al eliminar el ${documento}:`, error);
    return false;
  }
}

export const addAbono = async ({ db, pedido, monto, fecha, tipoAbono }) => {
  try {

    const dashboardRef = collection(db, "dashboard", baseDatos, "pedidos");

    var pagosArray = pedido.pagos

    pagosArray.push({
      monto,
      fecha,
      tipoAbono
    })


    // Intentar eliminar el cliente
    const setDocRef = await setDoc(doc(dashboardRef, `${pedido.id}`),
      { pagos: pagosArray },
      { merge: true }
    );

    // Si la operacion tiene éxito, retornar true
    return true;
  } catch (error) {
    // Si hay un error en alguna de las operaciones, retornar false
    console.error(`Error al agregar el abono:`, error);
    return false;
  }
}



export function convertirFormatoFecha(fechaString) {
  // Crear un objeto de fecha a partir de la cadena
  const fecha = new Date(fechaString);

  // Obtener el día del mes
  const dia = fecha.getDate();

  // Obtener el nombre del mes
  const meses = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
    "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];
  const mes = meses[fecha.getMonth()];

  // Obtener la hora y minutos
  const hora = fecha.getHours();
  const minutos = fecha.getMinutes();

  // Formatear la cadena resultante
  const resultado = `${dia} de ${mes} ${hora}:${minutos < 10 ? '0' : ''}${minutos} hrs`;

  return resultado;
}

export function convertirFormatoFechaAlternativo(fechaString) {
  // Crea un objeto Date a partir de la cadena de fecha
  const fecha = new Date(fechaString);

  // Obtiene el día, mes y año
  const dia = fecha.getDate();
  const mesIndex = fecha.getMonth();
  const año = fecha.getFullYear();

  // Array con los nombres de los meses
  const meses = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ];

  // Obtiene el nombre del mes usando el índice obtenido
  const mes = meses[mesIndex];

  // Formatea la nueva cadena de fecha
  const fechaFormateada = `${dia} de ${mes} del ${año}`;

  return fechaFormateada;
}

export function convertirFormatoFechaCorto(fechaString) {
  const fecha = new Date(fechaString);

  // Obtenemos los componentes de la fecha
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1; // ¡Recuerda que los meses comienzan en 0!
  const año = fecha.getFullYear() % 100; // Obtenemos los dos últimos dígitos del año

  // Formateamos los componentes para asegurarnos de que tengan dos dígitos
  const diaFormateado = dia < 10 ? `0${dia}` : dia;
  const mesFormateado = mes < 10 ? `0${mes}` : mes;
  const añoFormateado = año < 10 ? `0${año}` : año;

  // Creamos la cadena de fecha en el formato deseado
  const fechaFormateada = `${diaFormateado}-${mesFormateado}-${añoFormateado}`;

  return fechaFormateada;
}

export function sumarDiasHabilesTypeDate(fechaString, dias) {
  const fechaOriginal = new Date(fechaString);
  let fechaSumada = new Date(fechaOriginal);

  // Función para verificar si un día es fin de semana (sábado o domingo)
  const esFinDeSemana = (fecha) => {
    const dia = fecha.getDay();
    return dia === 0 || dia === 6;
  };

  // Iteramos sobre los días hábiles
  for (let i = 0; i < dias;) {
    fechaSumada.setDate(fechaSumada.getDate() + 1);

    // Si el día no es fin de semana, incrementamos el contador
    if (!esFinDeSemana(fechaSumada)) {
      i++;
    }
  }

  return fechaSumada;
}

export const formatNumberToPesos = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export function sumarDiasHabiles(fechaString, dias) {
  const fechaOriginal = new Date(fechaString);
  let fechaSumada = new Date(fechaOriginal);

  // Función para verificar si un día es fin de semana (sábado o domingo)
  const esFinDeSemana = (fecha) => {
    const dia = fecha.getDay();
    return dia === 0 || dia === 6;
  };

  // Iteramos sobre los días hábiles
  for (let i = 0; i < dias;) {
    fechaSumada.setDate(fechaSumada.getDate() + 1);

    // Si el día no es fin de semana, incrementamos el contador
    if (!esFinDeSemana(fechaSumada)) {
      i++;
    }
  }

  // Formateamos la fecha sumada
  const diaSumado = fechaSumada.getDate();
  const mesSumado = fechaSumada.getMonth() + 1;
  const añoSumado = fechaSumada.getFullYear() % 100;

  const diaFormateado = diaSumado < 10 ? `0${diaSumado}` : diaSumado;
  const mesFormateado = mesSumado < 10 ? `0${mesSumado}` : mesSumado;
  const añoFormateado = añoSumado < 10 ? `0${añoSumado}` : añoSumado;

  const fechaSumadaFormateada = `${diaFormateado}-${mesFormateado}-${añoFormateado}`;

  return fechaSumadaFormateada;
}