export const coloresCubiertaPostformado = [
    { label: "Polar White Solido 2111", value: "polar_white_solido_2111" },
    { label: "Vanilla Solido 2109", value: "vanilla_solido_2109" },
    { label: "Fashion White Solido 2125", value: "fashion_white_solido_2125" },
    { label: "Snow White Solido 2102", value: "snow_white_solido_2102" },
    { label: "Taupe Solido 2165", value: "taupe_solido_2165" },
    { label: "Latte Solido 2167", value: "latte_solido_2167" },
    { label: "Sandstone Solido 2119", value: "sandstone_solido_2119" },
    { label: "Ivory Solido 2324", value: "ivory_solido_2324" },
    { label: "White Solido 2114", value: "white_solido_2114" },
    { label: "Mouse Solido 2193", value: "mouse_solido_2193" },
    { label: "Humo Solido 2108", value: "humo_solido_2108" },
    { label: "Alumina Solido 2103", value: "alumina_solido_2103" },
    { label: "Gris Nube Solido 2189", value: "gris_nube_solido_2189" },
    { label: "Clay Solido 2257", value: "clay_solido_2257" },
    { label: "Stagnum Solido 2266", value: "stagnum_solido_2266" },
    { label: "Fossil Solido 2297", value: "fossil_solido_2297" },
    { label: "Shadow Solido 2259", value: "shadow_solido_2259" },
    { label: "Helmet Solido 2265", value: "helmet_solido_2265" },
    { label: "Dark Steet Solido 2315", value: "dark_steet_solido_2315" },
    { label: "Carbon Solido 2192", value: "carbon_solido_2192" },
    { label: "Ebano Solido 2110", value: "ebano_solido_2110" },
    { label: "Grafito Solido 2174", value: "grafito_solido_2174" },
    { label: "Brun Solido 2314", value: "brun_solido_2314" },
    { label: "Fragola Solido 2232", value: "fragola_solido_2232" },
    { label: "Magnolia Solido 2299", value: "magnolia_solido_2299" },
    { label: "Ballerina Solido 2318", value: "ballerina_solido_2318" },
    { label: "Brick Solido 2268", value: "brick_solido_2268" },
    { label: "Terra Solido 2225", value: "terra_solido_2225" },
    { label: "Red Solido 2137", value: "red_solido_2137" },
    { label: "Carmin Solido 2272", value: "carmin_solido_2272" },
    { label: "Burgundy Solido 2158", value: "burgundy_solido_2158" },
    { label: "Ruby Solido 2135", value: "ruby_solido_2135" },
    { label: "Red Pepper Solido 2238", value: "red_pepper_solido_2238" },
    { label: "Summer Solido 2305", value: "summer_solido_2305" },
    { label: "Golden Solido 2316", value: "golden_solido_2316" },
    { label: "Amarillo Solido 2163", value: "amarillo_solido_2163" },
    { label: "Orangine Solido 2295", value: "orangine_solido_2295" },
    { label: "Sunset Solido 2239", value: "sunset_solido_2239" },
    { label: "Amarillo Oro Solido 2152", value: "amarillo_oro_solido_2152" },
    { label: "Mustard Solido 2298", value: "mustard_solido_2298" },
    { label: "Bonsai Solido 2325", value: "bonsai_solido_2325" },
    { label: "Olive Solido 2248", value: "olive_solido_2248" },
    { label: "Herbal Solido 2274", value: "herbal_solido_2274" },
    { label: "Mint Solido 2236", value: "mint_solido_2236" },
    { label: "Bleen Solido 2224", value: "bleen_solido_2224" },
    { label: "Forest Solido 2252", value: "forest_solido_2252" },
    { label: "Citrus Solido 2205", value: "citrus_solido_2205" },
    { label: "Neon Green Solido 2186", value: "neon_green_solido_2186" },
    { label: "Sky Solido 2320", value: "sky_solido_2320" },
    { label: "Mist Solido 2251", value: "mist_solido_2251" },
    { label: "Teal Solido 2321", value: "teal_solido_2321" },
    { label: "Petroleo Solido 2237", value: "petroleo_solido_2237" },
    { label: "Turquesa Solido 2176", value: "turquesa_solido_2176" },
    { label: "Mediteranean Solido 2180", value: "mediteranean_solido_2180" },
    { label: "Caribe Solido 2182", value: "caribe_solido_2182" },
    { label: "Cerulean Solido 2221", value: "cerulean_solido_2221" },
    { label: "Lapizlasuli Solido 0888", value: "lapizlasuli_solido_0888" },
    { label: "Pacific Blue Solido 2170", value: "pacific_blue_solido_2170" },
    { label: "Ink Solido 2234", value: "ink_solido_2234" },
    { label: "Berenjena Solido 243", value: "berenjena_solido_243" },
    

    { label: "Larice Rustico Madera 1464", value: "larice_rustico_madera_1464" },
    { label: "Sea White Madera 1502", value: "sea_white_madera_1502" },
    { label: "Okumen Madera 1535", value: "okumen_madera_1535" },
    { label: "Natural Elm Madera 1499", value: "natural_elm_madera_1499" },
    { label: "Neutral Oak Madera 1498", value: "neutral_oak_madera_1498" },
    { label: "Organic Maple Madera 1511", value: "organic_maple_madera_1511" },
    { label: "Avalon Oak Madera 1558", value: "avalon_oak_madera_1558" },
    { label: "Segato Miele Madera 1468", value: "segato_miele_madera_1468" },
    { label: "Century Oak Madera 1554", value: "century_oak_madera_1554" },
    { label: "Sahara Madera 1565", value: "sahara_madera_1565" },
    { label: "Roble Lineal Oak Madera 1467", value: "roble_lineal_oak_madera_1467" },
    { label: "Noce Naturale Madera 1534", value: "noce_naturale_madera_1534" },
    { label: "Toscana Madera 1566", value: "toscana_madera_1566" },
    { label: "Ontario Oak Madera 1540", value: "ontario_oak_madera_1540" },
    { label: "Cottage Oak Madera 1532", value: "cottage_oak_madera_1532" },
    { label: "Provence Teak Cappuccino Madera 1526", value: "provence_teak_cappuccino_madera_1526" },
    { label: "French Walnut Madera 1510", value: "french_walnut_madera_1510" },
    { label: "Provincial Oak Madera 1501", value: "provincial_oak_madera_1501" },
    { label: "Beach Chestnut Madera 1518", value: "beach_chestnut_madera_1518" },
    { label: "Countryside Oak Madera 1533", value: "countryside_oak_madera_1533" },
    { label: "Segato Latte Madera 1469", value: "segato_latte_madera_1469" },
    { label: "Cincel Oak Madera 1508", value: "cincel_oak_madera_1508" },
    { label: "Rural Pine Madera 1505", value: "rural_pine_madera_1505" },
    { label: "Riverside Oak Madera 1541", value: "riverside_oak_madera_1541" },
    { label: "Rovere Fiumo Madera 1462", value: "rovere_fiumo_madera_1462" },
    { label: "Vintage Teak Madera 1516", value: "vintage_teak_madera_1516" },
    { label: "Olive Elm Madera 1503", value: "olive_elm_madera_1503" },
    { label: "Sandal Madera 1932", value: "sandal_madera_1932" },
    { label: "Cypress Cinnamon Madera 1490", value: "cypress_cinnamon_madera_1490" },
    { label: "Cypress Camel Madera 1489", value: "cypress_camel_madera_1489" },
    { label: "Noce Caffe Latte Madera 1832", value: "noce_caffe_latte_madera_1832" },
    { label: "Lucca Madera 1567", value: "lucca_madera_1567" },
    { label: "Noce Havana Madera 1555", value: "noce_havana_madera_1555" },
    { label: "Spiced Chestnut Madera 1517", value: "spiced_chestnut_madera_1517" },
    { label: "Roble Sepia Madera 1556", value: "roble_sepia_madera_1556" },
    { label: "Noce Canella Madera 1561", value: "noce_canella_madera_1561" },
    { label: "Italian Walnut Madera 1513", value: "italian_walnut_madera_1513" },
    { label: "Teca Villamayor Madera 1800", value: "teca_villamayor_madera_1800" },
    { label: "Palisander Madera 1839", value: "palisander_madera_1839" },
    { label: "Noce Madera 1841", value: "noce_madera_1841" },
    { label: "Grey Oak Madera 1829", value: "grey_oak_madera_1829" },
    { label: "Brown Oak Madera 1836", value: "brown_oak_madera_1836" },
    { label: "Drift Madera 1933", value: "drift_madera_1933" },
    { label: "Royal Walnut Madera 1536", value: "royal_walnut_madera_1536" },
    { label: "Wengue Tabaco Madera 1834", value: "wengue_tabaco_madera_1834" },
    { label: "Timber Blocks Madera 1521", value: "timber_blocks_madera_1521" },
    { label: "Grey Cedar Madera 1465", value: "grey_cedar_madera_1465" },
    { label: "Thermo Wood Madera 1506", value: "thermo_wood_madera_1506" },
    { label: "Chocolate Oak Madera 1808", value: "chocolate_oak_madera_1808" },
    { label: "Cafe Oak Madera 1830", value: "cafe_oak_madera_1830" },
    { label: "Granadillo Madera 1740", value: "granadillo_madera_1740" },
    { label: "Cerezo Clásico Madera 1474", value: "cerezo_clasico_madera_1474" },
    { label: "Mahogany Madera 1460", value: "mahogany_madera_1460" },
    { label: "Zapelly Caoba Madera 0380", value: "zapelly_caoba_madera_0380" },
    { label: "Wengue Madera 1807", value: "wengue_madera_1807" },
    { label: "Cerezo Agreste Madera 1473", value: "cerezo_agreste_madera_1473" },
    { label: "Haya Madera 1750", value: "haya_madera_1750" },
    { label: "Roble Natural Oak Madera 1802", value: "roble_natural_oak_madera_1802" },
    { label: "Fresno Madera 0470", value: "fresno_madera_0470" },
    { label: "Haya Natural Madera 1803", value: "haya_natural_madera_1803" },
    { label: "White Oak Madera 1336", value: "white_oak_madera_1336" },
    { label: "Perillo Madera 1320", value: "perillo_madera_1320" },
    { label: "Alpi Claro Madera 1799", value: "alpi_claro_madera_1799" },
    { label: "Maple Fusion Madera 1323", value: "maple_fusion_madera_1323" },
    { label: "Hard Rock Maple Madera 0791", value: "hard_rock_maple_madera_0791" },
    { label: "Maple Claro Madera 1322", value: "maple_claro_madera_1322" },
    { label: "Larice 3D Madera 1461", value: "larice_3d_madera_1461" },
    { label: "Contour Madera 1930", value: "contour_madera_1930" },

    { label: "Opak Sand 2267", value: "opak_sand_2267" },
    { label: "Opak White 2261", value: "opak_white_2261" },
    { label: "Opak Grey 2262", value: "opak_grey_2262" },
    { label: "Opak Titan 2263", value: "opak_titan_2263" },
    { label: "Opak Black 2260", value: "opak_black_2260" },
    { label: "Opak Ivory 2324", value: "opak_ivory_2324" },
    { label: "Opak Bleen 2224", value: "opak_bleen_2224" },
    { label: "Opak Forest 2252", value: "opak_forest_2252" },
    { label: "Opak Teal 2321", value: "opak_teal_2321" },
    { label: "Opak Fossil 2297", value: "opak_fossil_2297" },
    { label: "Opak Spiced Chestnut 1517", value: "opak_spiced_chestnut_1517" },
    { label: "Opak Industrial Concrete 3127", value: "opak_industrial_concrete_3127" },
    { label: "Opak Grey Slate 3156", value: "opak_grey_slate_3156" },

    { label: "Real Savannah Oak Ebony 1538", value: "real_savannah_oak_ebony_1538" },
    { label: "Real Savannah Oak Ganache 1524", value: "real_savannah_oak_ganache_1524" },
    { label: "Real Savannah Oak Honey 1523", value: "real_savannah_oak_honey_1523" },
    { label: "Real Savannah Oak Wheat 1522", value: "real_savannah_oak_wheat_1522" },
    { label: "Real Natural Elm Nordic 1531", value: "real_natural_elm_nordic_1531" },
    { label: "Real Natural Elm Neutral 1529", value: "real_natural_elm_neutral_1529" },
    { label: "Real Natural Elm Tobacco 1528", value: "real_natural_elm_tobacco_1528" },
    { label: "Real Statuario Marrone 3159", value: "real_statuario_marrone_3159" },
    { label: "Real Statuario Bianco 3158", value: "real_statuario_bianco_3158" },

    { label: "Granitos y Mármoles Florentino Negro", value: "granitos_y_marmoles_florentino_negro" },
    { label: "Granitos y Mármoles Palazzo 3171", value: "granitos_y_marmoles_palazzo_3171" },
    { label: "Granitos y Mármoles Tiziano 3172", value: "granitos_y_marmoles_tiziano_3172" },
    { label: "Granitos y Mármoles Imperiale 3157", value: "granitos_y_marmoles_imperiale_3157" },
    { label: "Granitos y Mármoles Véneto 3173", value: "granitos_y_marmoles_veneto_3173" },
    { label: "Granitos y Mármoles Atenas 3176", value: "granitos_y_marmoles_atenas_3176" },
    { label: "Granitos y Mármoles Alicante 3178", value: "granitos_y_marmoles_alicante_3178" },
    { label: "Granitos y Mármoles Positano 3177", value: "granitos_y_marmoles_positano_3177" },
    { label: "Granitos y Mármoles Santo Branco 3125", value: "granitos_y_marmoles_santo_branco_3125" },
    { label: "Granitos y Mármoles Marina di Carrara 3151", value: "granitos_y_marmoles_marina_di_carrara_3151" },
    { label: "Granitos y Mármoles Himalaya 3170", value: "granitos_y_marmoles_himalaya_3170" },
    { label: "Granitos y Mármoles Quarzite Snow 3007", value: "granitos_y_marmoles_quarzite_snow_3007" },
    { label: "Granitos y Mármoles Quarzite 3044", value: "granitos_y_marmoles_quarzite_3044" },
    { label: "Granitos y Mármoles Lapidus 3110", value: "granitos_y_marmoles_lapidus_3110" },
    { label: "Granitos y Mármoles Granitto Fossil 2724", value: "granitos_y_marmoles_granitto_fossil_2724" },
    { label: "Granitos y Mármoles Pietra Glaze 2719", value: "granitos_y_marmoles_pietra_glaze_2719" },
    { label: "Granitos y Mármoles Galaxy Champagne 0220", value: "granitos_y_marmoles_galaxy_champagne_0220" },
    { label: "Granitos y Mármoles Astral 3030", value: "granitos_y_marmoles_astral_3030" },
    { label: "Granitos y Mármoles Granito Metalizado 5030", value: "granitos_y_marmoles_granito_metalizado_5030" },
    { label: "Granitos y Mármoles Galaxy Graphite 0491", value: "granitos_y_marmoles_galaxy_graphite_0491" },

    { label: "Metalizados Stainless Steel Mirror 2027", value: "metalizados_stainless_steel_mirror_2027" },
    { label: "Metalizados Aluminum Mirror 2025", value: "metalizados_aluminum_mirror_2025" },
    { label: "Metalizados Brass Mirror 2026", value: "metalizados_brass_mirror_2026" },
    { label: "Metalizados Copper Mirror 2036", value: "metalizados_copper_mirror_2036" },
    { label: "Metalizados Copper Brushed 2038", value: "metalizados_copper_brushed_2038" },
    { label: "Metalizados Burnished Bronze 2039", value: "metalizados_burnished_bronze_2039" },
    { label: "Metalizados Aluminum Brush 2030", value: "metalizados_aluminum_brush_2030" },
    { label: "Metalizados Stainless Steel Brush 2035", value: "metalizados_stainless_steel_brush_2035" },
    { label: "Metalizados Stainless Steel HR 2020", value: "metalizados_stainless_steel_hr_2020" },
    { label: "Metalizados Metalized 2047", value: "metalizados_metalized_2047" },
    { label: "Metalizados Metalized Brush 2047", value: "metalizados_metalized_brush_2047" },
    { label: "Metalizados Brushed Rosegold 2508", value: "metalizados_brushed_rosegold_2508" },
    { label: "Metalizados Brushed Champagne 2506", value: "metalizados_brushed_champagne_2506" },
    { label: "Metalizados Oil Bronze 2507", value: "metalizados_oil_bronze_2507" },
    { label: "Metalizados Metallic Linen 2509", value: "metalizados_metallic_linen_2509" },
    { label: "Metalizados Bronze 2500", value: "metalizados_bronze_2500" },
    { label: "Metalizados Dune 2502", value: "metalizados_dune_2502" },
    { label: "Metalizados Moonlight 2501", value: "metalizados_moonlight_2501" },
    { label: "Metalizados Snowflake 2503", value: "metalizados_snowflake_2503" },
    { label: "Metalizados Estelar Fashion White 2125_ES", value: "metalizados_estelar_fashion_white_2125_ES" },
    { label: "Metalizados Glitter Cinder 2110_GT", value: "metalizados_glitter_cinder_2110_GT" },

    { label: "Diseños exclusivos Corten Metallic 3179", value: "disenos_exclusivos_corten_metallic_3179" },
    { label: "Diseños exclusivos Terra Steel 3116", value: "disenos_exclusivos_terra_steel_3116" },
    { label: "Diseños exclusivos Heavy Metal 3152", value: "disenos_exclusivos_heavy_metal_3152" },
    { label: "Diseños exclusivos Mineral Steel 3166", value: "disenos_exclusivos_mineral_steel_3166" },
    { label: "Diseños exclusivos Grey Slate 3156", value: "disenos_exclusivos_grey_slate_3156" },
    { label: "Diseños exclusivos Pimienta 4140", value: "disenos_exclusivos_pimienta_4140" },
    { label: "Diseños exclusivos Urban Concrete 3124", value: "disenos_exclusivos_urban_concrete_3124" },
    { label: "Diseños exclusivos Industrial Concrete 3127", value: "disenos_exclusivos_industrial_concrete_3127" },

    // Agrega más colores según sea necesario
];

export  const coloresCubiertaPiedra = [
    { label: "Marrón Cuarzo", value: "marron_cuarzo" },
    { label: "Blanco Espejado Galaxy Cuarzo", value: "blanco_espejado_galaxy_cuarzo" },
    { label: "Negro Espejado Galaxy Cuarzo", value: "negro_espejado_galaxy_cuarzo" },
    { label: "Blanco Cristal Cuarzo", value: "blanco_cristal_cuarzo" },
    { label: "Blanco Snow Cuarzo", value: "blanco_snow_cuarzo" },
    { label: "Blanco Absoluto Cuarzo", value: "blanco_absoluto_cuarzo" },
    { label: "Negro Absoluto Cuarzo", value: "negro_absoluto_cuarzo" },
    { label: "London Grey Cuarzo", value: "london_grey_cuarzo" },
    { label: "Atacama Cuarzo", value: "atacama_cuarzo" },
    { label: "Gris Espejado Cuarzo", value: "gris_espejado_cuarzo" },
    { label: "Beige Espejado Cuarzo", value: "beige_espejado_cuarzo" },
    { label: "Beige Cristal Cuarzo", value: "beige_cristal_cuarzo" },
    { label: "Moka Cuarzo", value: "moka_cuarzo" },
    { label: "Gris Vulcano Cuarzo", value: "gris_vulcano_cuarzo" },
    { label: "Gris Concreto Cuarzo", value: "gris_concreto_cuarzo" },
    { label: "Calacatta Blanco Cuarzo", value: "calacatta_blanco_cuarzo" },
    { label: "Calacatta Negro Cuarzo", value: "calacatta_negro_cuarzo" },
    { label: "Carrara Cuarzo", value: "carrara_cuarzo" },
    { label: "Marquina Cuarzo", value: "marquina_cuarzo" },

    { label: "Gris Mara Granito", value: "gris_mara_granito" },
    { label: "Gris Paris Granito", value: "gris_paris_granito" },
    { label: "Cafe Mara Granito", value: "cafe_mara_granito" },
    { label: "Amarillo Ornamental Granito", value: "amarillo_ornamental_granito" },
    { label: "Blanco Dallas Granito", value: "blanco_dallas_granito" },
    { label: "Negro Absoluto Granito", value: "negro_absoluto_granito" },
    { label: "Gris Algodón Granito", value: "gris_algodon_granito" },
  ];
