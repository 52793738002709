import React, { memo, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { DEFAULT_PATHS } from 'config.js';
import RouteItem from '../../RouteItem';

const RouteIdentifier = ({ routes, fallback = <div className="loading" />, notFoundPath = DEFAULT_PATHS.NOTFOUND, clientes, caja, pedidos, visitas, cotizaciones, codigos, db }) => (
  <Suspense fallback={fallback}>
    <Switch>
      {routes.map((route, rIndex) => {
        return (<RouteItem key={`r.${rIndex}`} {...route} clientes={clientes} pedidos={pedidos} caja={caja} visitas={visitas} cotizaciones={cotizaciones} codigos={codigos} db={db}/>)
      })}
      <Redirect to={notFoundPath} />
    </Switch>
  </Suspense>
);

export default memo(RouteIdentifier);
