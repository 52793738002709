
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Button, Dropdown, Form, Card, Pagination, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import CheckAll from 'components/check-all/CheckAll';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { deleteDocument } from 'helper';

const CustomersList = ({ db, clientes, pedidos, visitas }) => {
  const title = 'Lista de Clientes';
  const description = 'Lista de Clientes';

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalTittle, setModalTittle] = useState("");

  const history = useHistory();


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Resetear a la primera página
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtrar y paginar clientes
  const filteredClientes = clientes
  .filter((cliente) =>
    cliente.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    cliente.apellido.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => b.clienteId - a.clienteId);
  const allItems = Array.from({ length: filteredClientes.length }, (_, i) => i + 1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentClientes = filteredClientes.slice(indexOfFirstItem, indexOfLastItem);

  const checkItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((x) => x !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const toggleCheckAll = (allSelect) => {
    if (allSelect) {
      setSelectedItems(allItems);
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Dashboard</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}

          {/* Top Buttons Start */}
          
          {/* Top Buttons End */}
        </Row>
      </div>

      <Row className="mb-3">
        <Col md="5" lg="3" xxl="2" className="mb-1">
          {/* Search Start */}
          <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
            <Form.Control type="text" placeholder="Buscar" onChange={handleSearchChange} />
            <span className="search-magnifier-icon">
              <CsLineIcons icon="search" />
            </span>
            <span className="search-delete-icon d-none">
              <CsLineIcons icon="close" />
            </span>
          </div>
          {/* Search End */}
        </Col>
        
      </Row>

      {/* List Header Start */}
      <Row className="g-0 h-100 align-content-center d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort">
        <Col lg="1" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
          <div className="text-muted text-small cursor-pointer sort">ID</div>
        </Col>
        <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer sort">NAME</div>
        </Col>
        <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer sort">RUT</div>
        </Col>
        <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer sort">LOCATION</div>
        </Col>
        <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer sort">PHONE</div>
        </Col>
        <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer sort">ULTIMA VISITA</div>
        </Col>
        <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer sort">ULTIMO PEDIDO</div>
        </Col>
        <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
          <div className="text-muted text-small cursor-pointer"></div>
        </Col>
      </Row>
      {/* List Header End */}

      {/* List Items Start */}
      {currentClientes.map((cliente, index) => (
        <Card key={index} className={`mb-2 ${selectedItems.includes(index + 1) && 'selected'}`}>
          <Card.Body className="pt-0 pb-0 sh-30 sh-lg-8">
            <Row className="g-0 h-100 align-content-center">
              <Col xs="11" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-1 order-lg-1 h-lg-100 position-relative">
                <div className="text-muted text-small d-lg-none">Id</div>
                <NavLink to={`/customers/detail?id=${cliente.clienteId}`} className="text-truncate h-100 d-flex align-items-center">
                  {cliente.clienteId}
                </NavLink>
              </Col>
              <Col xs="6" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-3 order-lg-2">
                <div className="text-muted text-small d-lg-none">Name</div>
                <div className="text-alternate">{`${cliente.nombre != '' ? cliente.nombre : '- -'} ${cliente.apellido != '' ? cliente.apellido : '- -'}`}</div>
              </Col>
              <Col xs="6" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-3">
                <div className="text-muted text-small d-lg-none">Rut</div>
                <div className="text-alternate">{`${cliente.rut != '' ? cliente.rut : '- -'}`}</div>
              </Col>
              <Col xs="6" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-3">
                <div className="text-muted text-small d-lg-none">Location</div>
                <div className="text-alternate">{`${cliente.direccion != '' ? cliente.direccion : '- -'}`}</div>
              </Col>
              <Col xs="6" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-3">
                <div className="text-muted text-small d-lg-none">Phone</div>
                <div className="text-alternate">{`${cliente.telefono != '' ? cliente.telefono : '- -'}`}</div>
              </Col>
              <Col xs="6" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-4">
                <div className="text-muted text-small d-lg-none">Last Visit</div>
                <NavLink to="/customers/detail" className="text-truncate h-100 d-flex align-items-center body-link">
                  {/* {cliente.visitas.length > 0 ? cliente.visitas[cliente.visitas.length - 1].visitaId : '- -'} */}
                </NavLink>
              </Col>
              <Col xs="6" lg="1" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-5 order-lg-4">
                <div className="text-muted text-small d-lg-none">Last Order</div>
                <NavLink to="/customers/detail" className="text-truncate h-100 d-flex align-items-center body-link">
                {/* {cliente.pedidos.length > 0 ? cliente.pedidos[cliente.pedidos.length - 1].pedidoId : '- -'} */}
                </NavLink>
              </Col>
              <Col xs="1" lg="1" className="d-flex flex-column justify-content-center align-items-md-end mb-2 mb-md-0 order-2 text-end order-md-last">
              <div
                    className="sw-3 me-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {

                      var clickDelete = deleteDocument({
                        db: db,
                        objeto: cliente,
                        documento: 'clientes'
                      });
                      if (clickDelete) {
                        console.log("EL CLIENTE SE ELIMINO CON EXITO");
                        history.push(`/customers/list`)
                      } else {
                        setModalTittle("ERROR AL ELIMINAR EL CLIENTE");
                        setIsModalShow(true);
                        console.log("ERROR AL ELIMINAR EL CLIENTE");
                      }
  
                    }}
                  >
                    <CsLineIcons
                      icon="bin"
                      size="17"
                      className="text-primary"
                    />
                  </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
      {/* List Items End */}

      {/* Pagination Start */}
      <div className="d-flex justify-content-center mt-5">
        <Pagination>
          <Pagination.Prev
            className="shadow"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <CsLineIcons icon="chevron-left" />
          </Pagination.Prev>
          {Array.from({ length: Math.ceil(filteredClientes.length / itemsPerPage) }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              className="shadow"
              active={i + 1 === currentPage}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            className="shadow"
            disabled={currentPage === Math.ceil(filteredClientes.length / itemsPerPage)}
            onClick={() => handlePageChange(currentPage + 

1)}
          >
            <CsLineIcons icon="chevron-right" />
          </Pagination.Next>
        </Pagination>
      </div>
      {/* Pagination End */}
      {/* Categories Modal Start */}
      <Modal
        className="modal-center"
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
      >
        <Modal.Body closeButton>
          <Modal.Title style={{ textAlignLast: "center" }} as="h5">
            {modalTittle}
          </Modal.Title>
          <Row>
            <Col lg="6">
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                variant="primary"
                onClick={() => setIsModalShow(false)}
              >
                <span>Cerrar</span> <CsLineIcons icon="chevron-right" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Categories Modal End */}
    </>
  );
};

export default CustomersList;
