import React from "react";
import { Row, Col, Dropdown, Card, Badge } from "react-bootstrap";
import Rating from "react-rating";
import { NavLink } from "react-router-dom";
import HtmlHead from "components/html-head/HtmlHead";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import CsLineIcons from "cs-line-icons/CsLineIcons";
import PerformanceChart from "./components/PerformanceChart";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { convertirFormatoFechaCorto, obtenerColorBadge } from "helper";

const Dashboard = ({ clientes, pedidos, visitas, cotizaciones }) => {
  console.log("dashboard clientes: ", clientes);
  console.log("dashboard pedidos: ", pedidos);
  console.log("dashboard visitas: ", visitas);
  const title = "Dashboard";
  const description = "Ecommerce Dashboard Page";
  const history = useHistory();


  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink
          className="muted-link pb-1 d-inline-block hidden breadcrumb-back"
          to="/"
        >
          <span className="align-middle text-small ms-1">&nbsp;</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          Muebles a Tu Estilo
        </h1>
      </div>
      {/* Title End */}

      {/* Stats Start */}
      <div className="d-flex">
        <h2 className="small-title">Accesos Directos</h2>
      </div>
      <Row className="mb-5 g-2">
        <Col xs="6" md="3" lg="3">
          <Card className="h-100 hover-scale-up cursor-pointer" onClick={() => history.push(`/customers/add`)}>
            <Card.Body className="d-flex flex-column align-items-center">
              <div className="sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center border border-primary mb-4">
                <CsLineIcons icon="user" className="text-primary" />
              </div>
              <div className="text-primary cta-4">Crear Cliente</div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="6" md="3" lg="3">
          <Card className="h-100 hover-scale-up cursor-pointer" onClick={() => history.push(`/quotes/add`)}>
            <Card.Body className="d-flex flex-column align-items-center">
              <div className="sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center border border-primary mb-4">
                <CsLineIcons icon="content" className="text-primary" />
              </div>
              <div className="text-primary cta-4">Crear Cotizacion</div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="6" md="3" lg="3">
          <Card className="h-100 hover-scale-up cursor-pointer" onClick={() => history.push(`/visits/add`)}>
            <Card.Body className="d-flex flex-column align-items-center">
              <div className="sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center border border-primary mb-4">
                <CsLineIcons icon="navigate-diagonal" className="text-primary" />
              </div>
              <div className="text-primary cta-4">Crear Visita</div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="6" md="3" lg="3">
          <Card className="h-100 hover-scale-up cursor-pointer" onClick={() => history.push(`/orders/list`)}>
            <Card.Body className="d-flex flex-column align-items-center">
              <div className="sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center border border-primary mb-4">
                <CsLineIcons icon="cart" className="text-primary" />
              </div>
              <div className="text-primary cta-4">Ver Pedidos</div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="6" md="3" lg="3">
          <Card className="h-100 hover-scale-up cursor-pointer" onClick={() => history.push(`/caja/list`)}>
            <Card.Body className="d-flex flex-column align-items-center">
              <div className="sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center border border-primary mb-4">
                <CsLineIcons icon="cart" className="text-primary" />
              </div>
              <div className="text-primary cta-4">Caja</div>
            </Card.Body>
          </Card>
        </Col>

      </Row>
      {/* Stats End */}

      <Row>
        {/* Recent Visits Start */}
        <Col xl="6" className="mb-5">
          <h2 className="small-title">Visitas</h2>
          {visitas.slice().sort((a, b) => b.visitaId - a.visitaId).slice(0, 5).map((visita) => {
            const clienteVisita = clientes.find(
              (clienteSearch) =>
                clienteSearch.clienteId.toString() ===
                visita?.clienteId.toString()
            );
            // if (clienteVisita? === null) {
            //   return <div className="loading"/>
            // }
            return (
              <Card className="mb-2 sh-15 sh-md-6">
                <Card.Body className="pt-0 pb-0 h-100">
                  <Row className="g-0 h-100 align-content-center">
                    <Col
                      xs="10"
                      md="2"
                      className="d-flex align-items-center mb-3 mb-md-0 h-md-100"
                    >
                      <NavLink
                        to={`/visits/detail?id=${visita?.clienteId}`}
                        className="body-link stretched-link"
                      >
                        Visita #{visita?.visitaId}
                      </NavLink>
                    </Col>
                    <Col
                      xs="12"
                      md="4"
                      className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                    >
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {`${clienteVisita?.nombre != ""
                          ? clienteVisita?.nombre
                          : "- -"
                          } ${clienteVisita?.apellido != ""
                            ? clienteVisita?.apellido
                            : "- -"
                          }`}
                      </span>
                    </Col>
                    <Col
                      xs="12"
                      md="4"
                      className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                    >
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {`${visita?.direccion != "" ? visita?.direccion : "- -"}`}
                      </span>
                    </Col>
                    <Col
                      xs="12"
                      md="2"
                      className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                    >
                      <span>
                        {`${visita?.fechaVisita != '' ? convertirFormatoFechaCorto((visita?.fechaVisita).toDate()) : '- -'}`}
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
        </Col>
        {/* Recent Visits End */}

        {/* Recent Quotes Start */}
        <Col xl="6" className="mb-5">
          <h2 className="small-title">Cotizaciones</h2>
          {cotizaciones.slice().sort((a, b) => b.cotizacionId - a.cotizacionId).slice(0, 5).map((cotizacion) => {
            const clienteCotizacion = clientes.find(
              (clienteSearch) =>
                clienteSearch.clienteId.toString() ===
                cotizacion?.clienteId.toString()
            );

            return (
              <Card className="mb-2 sh-15 sh-md-6">
                <Card.Body className="pt-0 pb-0 h-100">
                  <Row className="g-0 h-100 align-content-center">
                    <Col
                      xs="10"
                      md="2"
                      className="d-flex align-items-center mb-3 mb-md-0 h-md-100"
                    >
                      <NavLink
                        to={`/quotes/detail?id=${cotizacion?.cotizacionId}`}
                        className="body-link stretched-link"
                      >
                        Cotización #{cotizacion?.cotizacionId}
                      </NavLink>
                    </Col>
                    <Col
                      xs="12"
                      md="4"
                      className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                    >
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {`${clienteCotizacion?.nombre != ""
                          ? clienteCotizacion?.nombre
                          : "- -"
                          } ${clienteCotizacion?.apellido != ""
                            ? clienteCotizacion?.apellido
                            : "- -"
                          }`}
                      </span>
                    </Col>
                    <Col
                      xs="12"
                      md="3"
                      className="d-flex align-items-center justify-content-md-start mb-1 mb-md-0 text-alternate"
                    >


                      {`${cotizacion?.fechaCotizacion != '' ? convertirFormatoFechaCorto((cotizacion?.fechaCotizacion).toDate()) : '- -'}`}
                    </Col>
                    <Col
                      xs="2"
                      md="3"
                      className="d-flex align-items-center text-muted mb-1 mb-md-0 justify-content-end justify-content-md-start"
                    >
                      <Badge bg="outline-primary" className="me-1">
                        {`${cotizacion?.estado != "" ? cotizacion?.estado : "- -"
                          }`}
                      </Badge>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
        </Col>
        {/* Recent Quotes End */}

        {/* Recent Orders Start */}
        <Col xl="6" className="mb-5">
          <h2 className="small-title">Pedidos</h2>
          {pedidos.slice().sort((a, b) => b.pedidoId - a.pedidoId).slice(0, 5).map((pedido) => {
            const clientePedido = clientes.find(
              (clienteSearch) =>
                clienteSearch.clienteId.toString() ===
                pedido?.clienteId.toString()
            );

            return (
              <Card className="mb-2 sh-15 sh-md-6">
                <Card.Body className="pt-0 pb-0 h-100">
                  <Row className="g-0 h-100 align-content-center">
                    <Col
                      xs="10"
                      md="2"
                      className="d-flex align-items-center mb-3 mb-md-0 h-md-100"
                    >
                      <NavLink
                        to={`/orders/detail?id=${pedido?.pedidoId}`}
                        className="body-link stretched-link"
                      >
                        Pedido #{pedido?.pedidoId}
                      </NavLink>
                    </Col>
                    <Col
                      xs="12"
                      md="4"
                      className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                    >
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {`${clientePedido?.nombre != ""
                          ? clientePedido?.nombre
                          : "- -"
                          } ${clientePedido?.apellido != ""
                            ? clientePedido?.apellido
                            : "- -"
                          }`}
                      </span>
                    </Col>
                    <Col
                      xs="12"
                      md="3"
                      className="d-flex align-items-center justify-content-md-start mb-1 mb-md-0 text-alternate"
                    >


                      {`${pedido?.fecha.entrega != '' ? convertirFormatoFechaCorto((pedido?.fecha.entrega).toDate()) : '- -'}`}
                    </Col>
                    <Col
                      xs="2"
                      md="3"
                      className="d-flex align-items-center text-muted mb-1 mb-md-0 justify-content-end justify-content-md-start"
                    >
                      <Badge bg={`${obtenerColorBadge({estado:pedido.estado})}`} className="me-1">
                        {`${pedido?.estado != "" ? pedido?.estado : "- -"}`}
                      </Badge>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
        </Col>
        {/* Recent Orders End */}

        {/* Recent customers Start */}
        <Col xl="6" className="mb-5">
          <h2 className="small-title">Clientes</h2>
          {clientes.slice().sort((a, b) => b.clienteId - a.clienteId).slice(0, 5).map((cliente) => {
            return (
              <Card className="mb-2 sh-15 sh-md-6">
                <Card.Body className="pt-0 pb-0 h-100">
                  <Row className="g-0 h-100 align-content-center">
                    <Col
                      xs="10"
                      md="2"
                      className="d-flex align-items-center mb-3 mb-md-0 h-md-100"
                    >
                      <NavLink
                        to={`/customers/detail?id=${cliente?.clienteId}`}
                        className="body-link stretched-link"
                      >
                        Cliente #{cliente?.clienteId}
                      </NavLink>
                    </Col>
                    <Col
                      xs="12"
                      md="4"
                      className="d-flex align-items-center mb-1 mb-md-0 text-alternate"
                    >
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {`${cliente?.nombre != ""
                          ? cliente?.nombre
                          : "- -"
                          } ${cliente?.apellido != ""
                            ? cliente?.apellido
                            : "- -"
                          }`}
                      </span>
                    </Col>
                    <Col
                      xs="12"
                      md="3"
                      className="d-flex align-items-center justify-content-md-start mb-1 mb-md-0 text-alternate"
                    >


                      {`${cliente?.rut != "" ? cliente?.rut : "- -"
                        }`}
                    </Col>
                    <Col
                      xs="12"
                      md="3"
                      className="d-flex align-items-center justify-content-md-start mb-1 mb-md-0 text-alternate"
                    >


                      {`${cliente?.telefono != "" ? cliente?.telefono : "- -"
                        }`}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
        </Col>
        {/* Recent customers End */}
      </Row>
    </>
  );
};

export default Dashboard;
