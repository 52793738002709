import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Card, Button, Col, Form, Row, Modal, FormControl } from "react-bootstrap";
import Select from "react-select";
import { useHistory } from 'react-router-dom';
import HtmlHead from "components/html-head/HtmlHead";
import CsLineIcons from "cs-line-icons/CsLineIcons";
import regionesComunasJson from "regionesComunasJson";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { addCaja, addCliente } from "helper";

const CajaIngreso = ({
  clientes,
  pedidos,
  visitas,
  cotizaciones,
  codigos,
  db,
}) => {
  const title = "Agregar ingreso a caja";
  const description = "Agregar ingreso a caja";

  const tiposDePago = [
    { label: "Efectivo", value: "efectivo" },
    { label: "Transferencia", value: "transferencia" },
    { label: "Debito", value: "debito" },
    { label: "Cheque", value: "cheque" },
    { label: "Otro", value: "otro" },
  ];

  const tipoCodigoIngreso = [
    { label: "No aplica", value: "no_aplica" },
    { label: "Pedido", value: "pedido" },
    { label: "Cotizacion", value: "cotizacion" },
  ];

  const history = useHistory();
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalTittle, setModalTittle] = useState("");

  const [nombre, setNombre] = useState("");
  const [valorPago, setValorPago] = useState("");
  const [selectedTipoPago, setSelectedTipoPago] = useState(tiposDePago[0]);
  const [selectedTipoCodigoIngreso, setSelectedTipoCodigoIngreso] = useState(tipoCodigoIngreso[0]);

  const [detalle, setDetalle] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [pedidoEncontrado, setPedidoEncontrado] = useState(
    pedidos.find(
      (pedidoSearch) =>
        pedidoSearch.pedidoId.toString() === pedidos[0].pedidoId.toString()
    )
  );

  const [cotizacionEncontrado, setCotizacionEncontrado] = useState(
    cotizaciones.find(
      (cotizacionSearch) =>
        cotizacionSearch.cotizacionId.toString() === cotizaciones[0].cotizacionId.toString()
    )
  );

  const [isValidNombre, setIsValidNombre] = useState(false);
  const [isValidValorPago, setIsValidValorPago] = useState(false);
  const [isValidDate, setIsValidDate] = useState(false);


  const handleNombreChange = (event) => {
    const inputValue = event.target.value;
    setNombre(inputValue);
    setIsValidNombre(validateText(inputValue));
  };
  const handleValorPagoChange = (event) => {
    setValorPago(event.target.value);
    if (event.target.value !== "") {
      setIsValidValorPago(true);
    } else {
      setIsValidValorPago(false);

    }
  };
  const handleTipoPagoChange = (selectedOption) => {
    setSelectedTipoPago(selectedOption);
  };

  const handleTipoCodigoIngresoChange = (selectedOption) => {
    setSelectedTipoCodigoIngreso(selectedOption);
  };

  const handleCodigoPedidoChange = (event) => {
    setPedidoEncontrado(
      pedidos.find(
        (pedidoSearch) =>
          pedidoSearch.pedidoId.toString() === event.target.value.toString()
      )
    );
  };

  const handleCodigoCotizacionChange = (event) => {
    setCotizacionEncontrado(
      cotizaciones.find(
        (cotizacionSearch) =>
          cotizacionSearch.cotizacionId.toString() === event.target.value.toString()
      )
    );
  };

  const handleDateSelect = (date) => {
    // Validar que la fecha seleccionada sea mayor que la fecha actual
    if (date) {
      setSelectedDate(date);
      setIsValidDate(true);
    } else {
      // Marcar como no válida y mostrar un mensaje de error
      setIsValidDate(false);
    }
  };
  const handleDetalleChange = (e) => {
    const inputValue = e.target.value;
    setDetalle(inputValue);
  };


  const validateText = (text) => {
    // La validación verifica que no esté vacío y solo contenga letras y espacios en blanco
    return text.trim().length > 0;
  };

  const [isFormValid, setIsFormValid] = useState(false);

  // Función para verificar si todas las validaciones son true
  const checkFormValidity = () => {
    return (
      isValidNombre &&
      isValidValorPago &&
      isValidDate
    );
  };

  // Efecto para actualizar el estado isFormValid cuando cambian las validaciones
  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [
    isValidNombre, isValidValorPago, isValidDate
  ]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink
          className="muted-link pb-1 d-inline-block hidden breadcrumb-back"
          to="/dashboard"
        >
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Dashboard</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End */}

      <Row>
        <Col xs="12" className="col-lg order-1 order-lg-0">
          {/* Address Start */}
          <Card className="mb-5">
            <Card.Body>
              <Form>
                <Row className="g-3">
                  <Col lg="12">
                    <Row>
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control
                            type="text"
                            value={nombre}
                            onChange={handleNombreChange}
                            isInvalid={!isValidNombre}
                          />
                          {!isValidNombre && (
                            <Form.Control.Feedback type="invalid">
                              Nombre inválido
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label>Tipo de pago</Form.Label>
                          <Select
                            classNamePrefix="react-select"
                            options={tiposDePago}
                            value={selectedTipoPago}
                            onChange={handleTipoPagoChange}
                            placeholder=""
                          />
                        </Form.Group>
                      </Col>

                    </Row>
                    <Row className="mt-2">
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label>Tipo de codigo</Form.Label>
                          <Select
                            classNamePrefix="react-select"
                            options={tipoCodigoIngreso}
                            value={selectedTipoCodigoIngreso}
                            onChange={handleTipoCodigoIngresoChange}
                            placeholder=""
                          />
                        </Form.Group>
                      </Col>
                      {
                        selectedTipoCodigoIngreso !== null ? 
                        selectedTipoCodigoIngreso.value === 'pedido' ?
                          <Col lg="6">
                            <Form.Group>
                              <Form.Label>Codigo pedido</Form.Label>
                              <Form.Select onChange={handleCodigoPedidoChange}>
                                {/* Mapea la lista de clientes para crear las opciones del select */}
                                {pedidos.map((pedido) => (
                                  <option
                                    key={pedido.pedidoId}
                                    value={pedido.pedidoId}
                                  >
                                    {`${pedido.pedidoId}`}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col> : selectedTipoCodigoIngreso.value === 'cotizacion' ?
                            <Col lg="6">
                              <Form.Group>
                                <Form.Label>Codigo cotizacion</Form.Label>
                                <Form.Select onChange={handleCodigoCotizacionChange}>
                                  {/* Mapea la lista de clientes para crear las opciones del select */}
                                  {cotizaciones.map((cotizacion) => (
                                    <option
                                      key={cotizacion.cotizacionId}
                                      value={cotizacion.cotizacionId}
                                    >
                                      {`${cotizacion.cotizacionId}`}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            : <></>: <></>
                      }

                    </Row>
                    <Row className="mt-2">
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label>Valor</Form.Label>
                          <div className="input-group">

                            <span className="input-group-text">$</span>
                            <div className="input-group-append"></div>
                            <FormControl
                              type="number"
                              value={valorPago}
                              onChange={handleValorPagoChange}
                              placeholder="Ingrese el valor"
                            />
                          </div>
                          {!isValidValorPago && (
                            <Form.Control.Feedback type="invalid">
                              Valor inválido
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg="6">
                        <Form.Group>
                          <Form.Label>Fecha</Form.Label>
                          <DatePicker

                            locale="es"
                            className={`form-control ${!isValidDate ? "is-invalid" : ""
                              }`}
                            selected={selectedDate}
                            onSelect={handleDateSelect}
                            onChange={(date) => handleDateSelect(date)}
                          />
                          {!isValidDate && (
                            <Form.Control.Feedback type="invalid">
                              Selecciona una fecha y hora válidas.
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Col>

                    </Row>
                    <Row className="mt-2">
                      <Col lg="12">
                        <Form.Group>
                          <Form.Label>Detalle</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={5}
                            value={detalle}
                            onChange={handleDetalleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col lg="12">
                  <Button
                    className="btn-icon btn-icon-end w-100 mt-4"
                    variant="primary"
                    disabled={!isFormValid}
                    onClick={() => {

                      var clickAddIngreso = addCaja({
                        db: db,
                        codigos: codigos,
                        nombre: nombre,
                        valor: valorPago,
                        tipoDePago: selectedTipoPago,
                        codigo: selectedTipoCodigoIngreso.value === 'pedido' ? {tipo:'pedido', codigo: pedidoEncontrado.pedidoId} : selectedTipoCodigoIngreso.value === 'cotizacion' ? {tipo:'cotizacion', codigo:  cotizacionEncontrado.cotizacionId}  : '', 
                        fecha: selectedDate,
                        detalle: detalle,
                        tipo: 'ingreso'
                      });
                      if (clickAddIngreso) {
                        console.log("EL CARGO SE AGREGO CON EXITO");
                        history.push(`/caja/list`)
                      } else {
                        setModalTittle("ERROR AL AGREGAR CARGO");
                        setIsModalShow(true);
                        console.log("ERROR AL AGREGAR CARGO");
                      }

                    }}
                  >
                    <span>Agregar ingreso</span>{" "}
                    <CsLineIcons icon="chevron-right" />
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/* Address End */}
        </Col>
      </Row>

      {/* Categories Modal Start */}
      <Modal
        className="modal-center"
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
      >
        <Modal.Body closeButton>
          <Modal.Title style={{ textAlignLast: 'center' }} as="h5">{modalTittle}</Modal.Title>
          <Row>
            <Col lg="6">
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                variant="primary"
                onClick={() => setIsModalShow(false)}
              >
                <span>Cerrar</span> <CsLineIcons icon="chevron-right" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Categories Modal End */}
    </>
  );
};

export default CajaIngreso;
