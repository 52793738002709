import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Card, Button, Col, Form, Row, Modal } from "react-bootstrap";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import HtmlHead from "components/html-head/HtmlHead";
import CsLineIcons from "cs-line-icons/CsLineIcons";
import regionesComunasJson from "regionesComunasJson";
import { addCliente, addVisita } from "helper";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { isValid } from "date-fns";


registerLocale("es", es);

const VisitsAdd = ({
  clientes,
  pedidos,
  visitas,
  cotizaciones,
  codigos,
  db,
}) => {
  const title = "Crear Visita";
  const description = "Crear Visita";

  const history = useHistory();
  const location = useLocation();

  const id = new URLSearchParams(location.search).get("id");
  const [isModalShow, setIsModalShow] = useState(false);
  const [modalTittle, setModalTittle] = useState("");

  const [clienteEncontrado, setClienteEncontrado] = useState(
    clientes.find(
      (clienteSearch) =>
        clienteSearch.clienteId.toString() === clientes[0].clienteId.toString()
    )
  );

  useEffect(() => {
    // Encuentra el cotizacion que coincida con el id
    if (id) {
        const clienteEnviado = clientes.find(
            (clienteSearch) =>
              clienteSearch.clienteId.toString() === id.toString()
          );
    
        setClienteEncontrado(clienteEnviado)
    }
  }, [id]);

  const handleClienteChange = (event) => {
    // Cuando se selecciona un cliente, actualiza el estado
    console.log("cliente select ", event.target.value);

    setClienteEncontrado(
      clientes.find(
        (clienteSearch) =>
          clienteSearch.clienteId.toString() === event.target.value.toString()
      )
    );
    updateButtonState();
  };

  const [detalle, setDetalle] = useState("");
  const [isValidDetalle, setIsValidDetalle] = useState(true);

  const handleDetalleChange = (e) => {
    const inputValue = e.target.value;
    setDetalle(inputValue);
    setIsValidDetalle(inputValue.trim().length > 0);
    updateButtonState();
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [isValidDate, setIsValidDate] = useState(true);

  const handleDateSelect = (date) => {
    // Validar que la fecha seleccionada sea mayor que la fecha actual
    if (date && date > new Date()) {
      setSelectedDate(date);
      setIsValidDate(true);
    } else {
      // Marcar como no válida y mostrar un mensaje de error
      setIsValidDate(false);
    }
    updateButtonState();
  };

  const [isButtonClickable, setIsButtonClickable] = useState(false);

  const updateButtonState = () => {
    setIsButtonClickable(
      isValidDate &&
        isValidDetalle &&
        clienteEncontrado !== undefined &&
        clienteEncontrado !== null
    );
  };

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink
          className="muted-link pb-1 d-inline-block hidden breadcrumb-back"
          to="/dashboard"
        >
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Dashboard</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End */}

      <Row>
        <Col xs="5" className="col-lg order-1 order-lg-0">
          {/* Address Start */}
          <Card className="mb-5">
            <Card.Body>
              <Form>
                <Row className="g-3">
                  <Col lg="12">
                    <Form.Group>
                      <Form.Label>Cliente</Form.Label>
                      <Form.Select onChange={handleClienteChange}>
                        {/* Mapea la lista de clientes para crear las opciones del select */}
                        {clientes.map((cliente) => (
                          <option
                            key={cliente.clienteId}
                            value={cliente.clienteId}
                          >
                            {`${cliente.nombre} ${cliente.apellido}`}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group>
                      <Form.Label>Detalle</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        value={detalle}
                        onChange={handleDetalleChange}
                        isInvalid={!isValidDetalle}
                      />
                      {!isValidDetalle && (
                        <Form.Control.Feedback type="invalid">
                          El detalle no puede estar vacío.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg="12">
                    <Form.Group>
                      <Form.Label>Fecha</Form.Label>
                      <DatePicker
                        inline
                        locale="es"
                        showTimeSelect
                        className={`form-control ${
                          !isValidDate ? "is-invalid" : ""
                        }`}
                        selected={selectedDate}
                        onSelect={handleDateSelect}
                        onChange={(date) => handleDateSelect(date)}
                      />
                      {!isValidDate && (
                        <Form.Control.Feedback type="invalid">
                          Selecciona una fecha y hora válidas en el futuro.
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col lg="12">
                  <Button
                    className="btn-icon btn-icon-end w-100 mt-4"
                    variant="primary"
                    disabled={!isButtonClickable}
                    onClick={() => {
                        const clienteVisita = clientes.find(
                            (clienteSearch) =>
                                clienteSearch.clienteId.toString() ===
                                clienteEncontrado.clienteId.toString()
                            );

                      var clickAddVisita = addVisita({
                        db: db,
                        codigos: codigos,
                        clienteVisita:clienteVisita,
                        detalle:detalle,
                        fechaVisita:selectedDate
                      });
                      if (clickAddVisita) {
                        console.log("LA VISITA SE AGREGO CON EXITO");
                        history.push(`/visits/list`)
                      } else {
                        setModalTittle("ERROR AL AGREGAR LA VISITA");
                        setIsModalShow(true);
                        console.log("ERROR AL AGREGAR LA VISITA");
                      }
                    }}
                  >
                    <span>Crear Visita</span>{" "}
                    <CsLineIcons icon="chevron-right" />
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/* Address End */}
        </Col>
        <Col lg="7">
          <h2 className="small-title">Cliente</h2>
          <Card className="mb-5">
            <Card.Body className="mb-n5">
              <div className="mb-5">
                <p className="text-small text-muted mb-2">Datos</p>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="user"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${
                    clienteEncontrado
                      ? clienteEncontrado.nombre != ""
                        ? clienteEncontrado.nombre
                        : "- -"
                      : "- -"
                  } ${
                    clienteEncontrado
                      ? clienteEncontrado.apellido != ""
                        ? clienteEncontrado.apellido
                        : "- -"
                      : "- -"
                  }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="pin"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${
                    clienteEncontrado
                      ? clienteEncontrado.rut != ""
                        ? clienteEncontrado.rut
                        : "- -"
                      : "- -"
                  }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="pin"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${
                    clienteEncontrado
                      ? clienteEncontrado.direccion != ""
                        ? clienteEncontrado.direccion
                        : "- -"
                      : "- -"
                  }`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons
                        icon="phone"
                        size="17"
                        className="text-primary"
                      />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${
                    clienteEncontrado
                      ? clienteEncontrado.telefono != ""
                        ? clienteEncontrado.telefono
                        : "- -"
                      : "- -"
                  }`}</Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Categories Modal Start */}
      <Modal
        className="modal-center"
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
      >
        <Modal.Body closeButton>
          <Modal.Title style={{ textAlignLast: "center" }} as="h5">
            {modalTittle}
          </Modal.Title>
          <Row>
            <Col lg="6">
              <Button
                className="btn-icon btn-icon-end w-100 mt-4"
                variant="primary"
                onClick={() => setIsModalShow(false)}
              >
                <span>Cerrar</span> <CsLineIcons icon="chevron-right" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* Categories Modal End */}
    </>
  );
};

export default VisitsAdd;
